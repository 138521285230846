import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import VueClipboard from "vue-clipboard2";
import store from './store';
import VueTelInput from 'vue-tel-input';
import vueVimeoPlayer from "vue-vimeo-player";
import 'vue-tel-input/vue-tel-input.css';
import './css/index.scss';
import { useVuelidate } from '@vuelidate/core'
import { createGtm } from "@gtm-support/vue-gtm";
import currencyHelper from './mixins/currencyHelper.vue'

const app = createApp(App)

app.use(router)
app.use(VueClipboard)
app.use(VueTelInput)
app.use(vueVimeoPlayer)
app.use(store)
app.use(useVuelidate)

app.use(createGtm({
    id: "GTM-M4K36DC",
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: ["homepage"],
    trackOnNextTick: false,
}))

Sentry.init({
    app,
    dsn: "https://8d90988506dd42756a9791881b5e57ca@o4506036263256064.ingest.sentry.io/4506036266795008",
    integrations: [
        Sentry.browserTracingIntegration({
            tracePropagationTargets: [
                "localhost",
                /^https:\/\/presentandsimple.com\.io\/api/,
            ],
        }),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.mixin(currencyHelper)
app.mount('#app')

