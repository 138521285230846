<template>
    <div v-if="this.isSortShown"
         class="w-51 -mt-2 text-right cursor-pointer absolute z-20 justify-end right-0 border border-gray-lightest lg:mr-3">
        <div v-click-outside="closePopup">
            <div @click="sort('default')"
                 :class="sortBy === 'default' ? 'underlinedPositions ' : ''"
                 class="pr-5 text-right h-8 bg-white flex items-center justify-end text-14 text-gray-main  cursor-pointer border border-white hover:border-black">
                Наш выбор
            </div>
            <div @click="sort('price')"
                 :class="sortBy === 'price' ? 'underlinedPositions' : ''"
                 class="pr-5 text-right h-8 bg-white flex items-center justify-end text-14 text-gray-main cursor-pointer border border-white hover:border-black">
                По возрастанию цены
            </div>
            <div @click="sort('-price')"
                 :class="sortBy === '-price' ? 'underlinedPositions' : ''"
                 class="pr-5 text-right h-8 bg-white flex items-center justify-end text-14 text-gray-main cursor-pointer border border-white hover:border-black">
                По убыванию цены
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import vClickOutside from 'click-outside-vue3';


export default {
    name: "Sorting",
    props: {
        categoryId: null,
        sortBy: null,
        isSortShown: null,
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        ...mapActions({
            sortAndFilter: 'sortAndFilter',
            putCategoriesIntoFilter: 'putCategoriesIntoFilter',
            setRangeOfPricesValue: 'setRangeOfPricesValue',
            putTypeOfSortingIntoPayload: 'putTypeOfSortingIntoPayload',
        }),
        closePopup() {
            this.$emit('closeSortingPopup', {
                isFiltersPopupActive: false,
                isSortPopupActive: false
            })
        },
        sort(type) {
            this.$emit('putTypeOfSortingIntoSortingAndFiltration', {type: type})
        },
    },
    computed: {
        ...mapGetters({
            payload: 'payload',
            minValue: 'minValue',
            maxValue: 'maxValue',
            rangeOfPricesValue: 'rangeOfPricesValue',
            filteredProducts: 'filteredProducts',
            availableFiltersForCategoryLocal: 'availableFiltersForCategoryLocal'
        }),
    },
    mounted() {
    },
}

</script>

<style scoped>
.underlinedPositions {
    text-decoration: underline;
    text-underline-offset: 4px;
}
</style>



