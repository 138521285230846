<template>
    <div
        class="bg-bg-brown bg-opacity-25 h-full w-full fixed z-40 flex left-0 right-0 bottom-0 items-right justify-end"
        ref="popup_wrapper"
    >
        <div class="p-5 w-98 bg-white shadow z-50 overflow-y-auto no-scrollbar">
            <div class="w-full flex justify-end items-center relative">
                <div class="cursor-pointer absolute" :style="{zIndex: 100}" @click="closePopup">
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 10L10 1" stroke="rgba(147, 147, 147, 1)" stroke-linecap="square" />
                        <path
                            d="M1 1.00024L10 10.0002"
                            stroke="rgba(147, 147, 147, 1)"
                            stroke-linecap="square"
                        />
                    </svg>
                </div>
            </div>
            <div class="">
                <slot> </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupOnRightSide',

    methods: {
        closePopup() {
            this.$emit('closePopup')
        }
    },
    mounted() {
        let vm = this
        document.addEventListener('click', function (item) {
            if (item.target === vm.$refs['popup_wrapper']) {
                vm.closePopup()
            }
        })
    }
}
</script>

<style scoped></style>
