<template>
    <div v-if="deliveryRegionDetail !== null" class="mb-7">
        <div v-if=" deliveryRegionDetail.relationships.deliveryType.data.id === 1">
            <div class="uppercase text-11 text-black text-left flex-1 lg:text-13">Выберите дату и время доставки</div>
            <div>
                <div class="mt-5 text-11 text-gray-light text-13">
                    Дата
                </div>
                <div class="mt-2">
                    <div v-if="deliveryRegionDetail.relationships.deliveryDates.length" class="">
                        <div class="flex grid grid-cols-5 divide-x divide-gray-lightest border border-gray-lightest">
                            <div v-for="item in deliveryRegionDetail.relationships.deliveryDates" class="">
                                <div @click="selectedDateDelivery(item)"
                                     :class="[delivery.date === item ? 'py-1 px-5 text-11 bg-white border border-black focus:outline-none cursor-pointer lg:text-13'
                                     :'py-1 px-5 text-11 bg-white focus:outline-none hover:bg-black hover:text-white cursor-pointer lg:text-13']">
                                    <div class="flex items-center justify-center"> {{ new Date(item).toLocaleDateString('ru').slice(0,5) }}</div>
                                    <div class="flex items-center justify-center"> {{ new Date(item).toLocaleString("ru", options) }}.</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 text-11 text-gray-light text-13">
                            Время
                        </div>
                        <div class="mt-2 relative" v-click-outside="hideSelect">
                            <div class="flex items-center cursor-pointer" @click="areOptionsVisible=!areOptionsVisible">
                                <div class="text-11 text-black lg:text-13">
                                    {{delivery.time_interval}}
                                </div>
                                <div class="ml-14 lg:hidden">
                                    <svg :class="[areOptionsVisible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 4L7 1" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1.00024L4 4.00024" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                                <div class="hidden lg:block lg:ml-14">
                                    <svg :class="[areOptionsVisible ? 'transform rotate-180' : '']"
                                         width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 5.5L10 1" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1.00037L5.5 5.50037" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                            <div class="mt-6 left-0 top-0 absolute z-30 bg-white cursor-pointer border-t border-l border-r border-gray-lighter" v-if="areOptionsVisible">
                                <div v-for="time in deliveryRegionDetail.relationships.deliveryTimeIntervals.data"
                                    :key="time.id"
                                     class="h-6 pl-2 pr-18 flex items-center justify-center text-11 cursor-pointer hover:bg-black hover:text-white bg-white align-middle border-b border-gray-lighter lg:h-9 lg:text-13"
                                     @click="selectOption(time)">
                                    {{time.attributes.from.slice(0,5)}} - {{time.attributes.to.slice(0,5)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import vClickOutside from 'click-outside-vue3';

export default {
    name: "CartDeliveryDateIntervalBlock",
    props: {
        deliveryRegionDetail:null
    },
    data: () =>({
        options : {
            weekday: 'short',
            timezone: 'UTC',

        },
        areOptionsVisible: false,
        deliveryDate:null,
        deliveryTimeInterval:null,
    }),
    methods: {
        ...mapActions({
            setDeliverySelectedDateVuex: 'setDeliverySelectedDate',
            setDeliveryTimeIntervalIdVuex: 'setDeliveryTimeIntervalId',
            setDeliveryTimeIntervalVuex: 'setDeliveryTimeInterval',

        }),
        selectOption (option) {
            this.setDeliveryTimeIntervalIdVuex(option.id)
            this.setDeliveryTimeIntervalVuex(option.attributes.from.slice(0,5) + ' - ' + option.attributes.to.slice(0,5))
            this.hideSelect();
        },
        hideSelect() {
            this.areOptionsVisible = false;
        },
        selectedDateDelivery(date) {
            this.setDeliverySelectedDateVuex(date)
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        ...mapGetters({
           delivery:'delivery'
        }),
    },

    mounted () {
       // document.addEventListener('click', this.hideSelect.bind(this), true);
        if(this.deliveryRegionDetail !== null && this.delivery.date === null && this.delivery.time_interval_id === null ) {
           this.setDeliveryTimeIntervalIdVuex(this.deliveryRegionDetail.relationships.deliveryTimeIntervals.data[0].id)
           this.setDeliveryTimeIntervalVuex(this.deliveryRegionDetail.relationships.deliveryTimeIntervals.data[0].attributes.from.slice(0,5) + ' - ' + this.deliveryRegionDetail.relationships.deliveryTimeIntervals.data[0].attributes.to.slice(0,5))
           this.setDeliverySelectedDateVuex(this.deliveryRegionDetail.relationships.deliveryDates[0])
        }
    },
    beforeDestroy () {
        document.removeEventListener('click', this.hideSelect)
    }
}
</script>

<style scoped>

</style>
