<template>
    <div class="mt-9">
        <div v-if="!product.data.attributes.active_stock_balance && !product.data.attributes.not_for_sale"
             class="mb-3 text-14 font-normal text-left text-green-dark font-bold">
            <span v-if="product.data.attributes.hasPreorderStockBalance">Предзаказ</span>
            <span v-else-if="product.data.attributes.coming_soon">Скоро в продаже</span>
            <span v-else-if="product.data.attributes.retail_stock_balance">Только в магазинах</span>
            <span v-else>Нет в наличии</span>
        </div>
        <div class="text-18 text-black uppercase tracking-widest">{{ product.data.attributes.title }}</div>
        <div class="mt-3 flex text-14 uppercase">
            <div v-if="product.data.attributes.discount" class="text-black">
                {{renderPriceFormat(product.data.attributes.cost - product.data.attributes.discount.data.attributes.amount)}} ₽
                <span class="ml-1 text-gray-main-2 line-through lg:ml-4">{{renderPriceFormat(product.data.attributes.cost)}} ₽</span>
            </div>
            <div v-else class="text-black">{{renderPriceFormat(product.data.attributes.cost)}} ₽</div>
        </div>
    </div>
</template>

<script>
export default {   
    name: "ProductInfoBlock",
    props: {
        product: null
    }
}

</script>

<style scoped>
</style>
