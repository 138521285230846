<template>
    <div class="flex-1">
        <Loading v-if="isLoading"/>
        <div v-else>
            <div v-if="!currentStep.isFinish" class="flex-none lg:flex">
                <div v-if="formData.currentCardDesign" class="w-full lg:w-1/2">
                    <div class="flex items-center lg:h-full relative lg:px-[81px] lg:py-[106px] px-[65px] py-[113px] bg-[#F3F0EB]">
                        <img v-if="currentStep.number > 1" @click="previousStep" class="back-btn" src="/back.svg" alt="account">
                        <div class="relative object-cover w-full rounded-lg overflow-hidden">
                            <img class="current-delign_img" :src="formData.currentCardDesign.image">
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-1/2">
                    <div class="steps_wrapper flex-none flex justify-center">
                        <div class="steps_inner">
                            <div class="mb-[25px]">
                                <h1 class="text-left lg:mt-[40px] mt-9 text-18 text-black uppercase tracking-widest">{{ currentStep.title }}</h1>
                                <p class="mt-[10px]" v-if="formData.giftSum && currentStep.number === 2">{{ renderPriceFormat(formData.giftSum) }} ₽</p>
                            </div>
                            <div class="steps_item" v-if="currentStep.number === 1">
                                <div class="mt-[20px]">
                                    <p class="text-14 mb-[15px]">Выберите дизайн</p>
                                    <div class="design-list_wrapper mt-[10px]">
                                        <div 
                                            v-for="cardDesign in cardDesigns" 
                                            :key="cardDesign.id" 
                                            :class="['design-list_item', {'active': cardDesign.id == formData.currentCardDesign.id}]"
                                            @click="() => selectCardDesign(cardDesign.id)"
                                        >
                                            <img :src="cardDesign.preview" />
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-[20px]">
                                    <p class="text-14 mb-[15px]">Введите сумму или выберите номинал</p>
                                    <div>
                                        <input 
                                            class="input-defailt focus:border-black" 
                                            type="text" 
                                            placeholder="500–500 000 ₽" 
                                            :value="fieldSumValue"  
                                            @click="sumFocusAction"
                                            @keydown="sumUpdate"
                                            @change="sumUpdate"
                                            @keyup="() => { startStepValidation() }"
                                        />
                                    </div>
                                    <div v-if="isError('giftSum')" class="field-row_error">
                                        {{ isError('giftSum').message }}
                                    </div>
                                    <div class="mt-[10px] sum-list_fade-gradient">
                                        <div class="sum-list_wrapper">
                                            <div class="sum-list_inner">
                                                <div 
                                                    v-for="sum in giftSumList"
                                                    :class="['sum-list_item', {'active': sum == formData.giftSum}]" 
                                                    @click="() => { formData.giftSum = sum; startStepValidation() }" 
                                                >
                                                    {{ renderPriceFormat(sum) }} ₽
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="steps_item" v-if="currentStep.number === 2">
                                <AuthByPhone v-if="getVerificationOfUser" @authByPhoneSuccessClosePopup="getUserAuthData"/>
                                <div v-else>
                                    <div class="mt-[20px]">
                                        <p class="text-14 mb-[15px]">Куда отправить</p>
                                        <div>
                                            <div>
                                                <label>
                                                    <input @focus="() => { removeValidationErrByFieldName('emailOrPhone') }" class="custom-checkbox" value="phone" v-model="formData.giftType" type="radio" name="giftType">
                                                    <span class="text-14">Телефон</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input @focus="() => { removeValidationErrByFieldName('emailOrPhone') }" class="custom-checkbox" value="email" v-model="formData.giftType" type="radio" name="giftType">
                                                    <span class="text-14">E-mail</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-[20px]">
                                        <div class="mb-[10px]">
                                            <div v-if="formData.giftType === 'email'">
                                                <input 
                                                    v-model="formData.recipientEmail" 
                                                    class="input-defailt focus:border-black" 
                                                    type="text" 
                                                    placeholder="E-mail получателя"
                                                    @click="() => { removeValidationErrByFieldName('emailOrPhone') }"
                                                />
                                            </div>
                                            <div v-else>
                                                <vue-tel-input 
                                                    class="focus:border-black"
                                                    v-model="formData.recipientPhone" 
                                                    :inputOptions="{ placeholder: '(___) __-__-___' }"
                                                    :preferredCountries="['ru', 'by', 'kz']"
                                                    :defaultCountry="'ru'"
                                                    :mode="'international'"
                                                    @focus="() => { removeValidationErrByFieldName('emailOrPhone') }"
                                                />
                                            </div>
                                            <div v-if="isError('emailOrPhone')" class="field-row_error">
                                                {{ isError('emailOrPhone').message }}
                                            </div>
                                        </div>
                                        <div class="field-row_wrapper mb-[10px]">
                                            <input 
                                                v-model="formData.cameOutName" 
                                                class="input-defailt focus:border-black" 
                                                type="text" placeholder="Имя получателя"
                                                @focus="() => { removeValidationErrByFieldName('cameOutName') }"
                                            />
                                            <div v-if="isError('cameOutName')"  class="field-row_error">
                                                {{ isError('cameOutName').message }}
                                            </div>
                                        </div>
                                        <div v-if="formData.giftType === 'email'" class="field-row_wrapper mb-[10px]">
                                            <input @keydown="() => { validationByCurrentTemplate('congratulationText') }" v-model="formData.congratulationText" class="input-defailt focus:border-black" type="text" placeholder="Текст поздравления"/>
                                            <div v-if="isError('congratulationText')" class="field-row_error">
                                                {{ isError('congratulationText').message }}
                                            </div>
                                        </div>
                                        <div class="field-row_wrapper mb-[10px]">
                                            <input 
                                                v-model="formData.sendersName" 
                                                class="input-defailt focus:border-black" 
                                                type="text" 
                                                placeholder="Имя отправителя"
                                                @focus="() => { removeValidationErrByFieldName('sendersName') }"
                                            />
                                            <div v-if="isError('sendersName')" class="field-row_error">
                                                {{ isError('sendersName').message }}
                                            </div>
                                        </div>
                                        <!--<div class="field-row_wrapper mb-[10px]">
                                            <input 
                                                v-model="formData.senderEmail"
                                                class="input-defailt focus:border-black" 
                                                type="text" 
                                                placeholder="E-mail отправителя"
                                                @focus="() => { removeValidationErrByFieldName('senderEmail') }"
                                            />
                                            <div v-if="isError('senderEmail')" class="field-row_error">
                                                {{ isError('senderEmail').message }}
                                            </div>
                                        </div>-->
                                    </div>
                                    <!--<div class="field-row_wrapper mt-[20px]">
                                        <p>Часовой пояс</p>
                                        <select v-model="formData.timeZone" class="input-defailt">
                                            <option v-for="timeZone in timeZones" :key="timeZone.value" :value="timeZone.value" selected>{{ timeZone.label }}</option>
                                        </select>
                                        <div v-if="isError('timeZone')" class="field-row_error">
                                            {{ isError('timeZone').message }}
                                        </div>
                                    </div>-->
                                    <div class="field-row_wrapper mt-[20px] mb-[10px]">
                                        <p class="text-14 my-[10px]">Дата и время отправки (московское время)</p>
                                        <input 
                                            v-model="formData.dispatchDate" 
                                            class="input-defailt focus:border-black" 
                                            type="datetime-local" 
                                            :placeholder="new Date().toLocaleString()"
                                            @focus="() => { removeValidationErrByFieldName('dispatchDate') }"
                                        />
                                        <div v-if="isError('dispatchDate')" class="field-row_error">
                                            {{ isError('dispatchDate').message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="steps_item" v-if="currentStep.number === 3">
                                
                            </div>
                            <div v-if="controlVisibleStatus">
                                <div class="mt-[30px]">
                                    <button 
                                        @click="nextStep" 
                                        class="w-full h-[38px] mt-2.5 text-14 font-normal flex items-center justify-center focus:outline-none border border-none bg-black-main text-white"
                                        v-html="`${currentStep.btnText} ${formData.giftSum ? ` <span class='mr-[8px] ml-[8px]'>·</span> ${ renderPriceFormat(formData.giftSum)} ₽` : ``}`"
                                    >
                                    </button>
                                    <button v-if="currentStep.number === 1" data-popmechanic-call-form="77584" class="w-full h-9 mt-2.5 text-14 font-normal flex items-center justify-center focus:outline-none border border-black bg-white-main text-black">
                                        Намекнуть о подарке
                                    </button>
                                </div>
                                <div class="mt-[15px]">
                                    <div>
                                        <span class="focus:outline-none text-black text-left text-14 flex-1 border-b border-black cursor-pointer" @click="() => { dialogVisible = true }">Условия использования</span>
                                    </div>
                                    <div class="mt-[30px] mb-[45px]">
                                        <p class="text-14 my-[0px]">Карта Present & Simple станет знаком внимания для ценителей бренда и прекрасным подарком для тех, кто пока не определился с предметом мечтаний. Электронная карта будет действовать при совершении покупок на сайте.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="flex-none lg:flex justify-center">
                <p class="mt-[20px] text-center">Электронная подарочная карта успешно оформлена и будет<br /> отправлена получателю 01.02.2025 в 12:30 по московскому времени</p>
            </div>
            <PopupOnRightSide v-if="dialogVisible" @closePopup="closePopup">
                <GiftCertificateUsingRulesDoc />
            </PopupOnRightSide>
        </div>
    </div>
</template>

<script>
import axios from '@/api';
import { mapActions, mapGetters } from "vuex"
import PopupOnRightSide from "../components/popup/PopupOnRightSide.vue";
import AuthByPhone from "../components/Auth/AuthByPhone.vue"
import Loading from "../components/Loading";
import GiftCertificateUsingRulesDoc from "../components/Documents/GiftCertificateUsingRulesDoc.vue";

export default {
    name: 'GiftCertifacateNew',
    components: {
        AuthByPhone,
        PopupOnRightSide,
        Loading,
        GiftCertificateUsingRulesDoc
    },
    data: () => {
        return {
            steps: [
                { 
                    id: 1, 
                    number: 1, 
                    title: 'Подарочная карта', 
                    description: 'Вступительный шаг', 
                    btnText: "Перейти к оформлению",
                    type: null, 
                    isFinish: false 
                },
                { 
                    id: 2, 
                    number: 2, 
                    title: 'Электронная подарочная карта', 
                    description: 'Заполнение данных о получателе',
                    btnText: "Перейти к оплате",
                    type: null, 
                    isFinish: false
                },
            ],
            cardDesigns: [],
            currentCardDesign: {
                id: 1,
                img: "https://storage.staging.presentandsimple.com/12629/full-page-block.jpg"
            },
            timeZones: [
                { label: "Московское время", value: "+3" },
                { label: "Калининградское (МСК-1)", value: "-1" },
                { label: "Самарское (МСК+1)", value: "+1" },
                { label: "Екатеринбургское (МСК+2)", value: "+2" },
                { label: "Омское (МСК+3)", value: "+3" },
                { label: "Красноярское (МСК+4)", value: "+4" },
                { label: "Иркутское (МСК+5)", value: "+5" },
                { label: "Якутское (МСК+6)", value: "+6" },
                { label: "Владивостокское (МСК+7)", value: "+7" },
                { label: "Среднеколмыкское (МСК+8)", value: "+8" },
                { label: "Камчатское (МСК+9)", value: "+9" },
            ],
            giftSumList: ['5000', '10000', '30000', '50000', '100000', '200000'],
            validation: [
                { 
                    stepId: 1, 
                    fields: [
                        {
                            fieldName: 'currentCardDesign',
                            ruLiteral: 'выбранный дизайн',
                            isRequired: true
                        },
                        {
                            fieldName: 'giftSum',
                            ruLiteral: 'сумма',
                            isRequired: true,
                            validationAction: (value) => {
                                if(500 > value || value > 500000) return 'Сумма должна быть не менее 500 ₽ и не более 500 000 ₽'
                            }
                        }
                    ]
                },{
                    stepId: 2,
                    fields: [
                        {
                            fieldName: 'cameOutName',
                            ruLiteral: 'имя получалеля',
                            isRequired: true
                        },
                        {
                            fieldName: 'sendersName',
                            ruLiteral: 'имя отправителя',
                            isRequired: true
                        },
                        {
                            fieldName: 'dispatchDate',
                            ruLiteral: 'дата и время отправки',
                            isRequired: true
                        },
                        {
                            fieldName: 'congratulationText',
                            ruLiteral: 'текст поздравления',
                            validationAction: (value) => {
                                if(value.length > 200) return 'Текст поздравления не должен превышать 200 символов'
                            }
                        },
                        /*{
                            fieldName: 'senderEmail',
                            ruLiteral: 'e-mail отправителя',
                            isRequired: true,
                            validationAction: (value) => {
                                const emailTemplate = !String(value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                                if(emailTemplate) return 'Некорректный email'
                            }
                        }*/
                    ]
                }
            ],
            validationErrors: [],
            currentStep: { 
                id: 1, 
                number: 1, 
                title: 'Подарочная карта', 
                description: 'Вступительный шаг', 
                btnText: "Перейти к оформлению",
                type: null, 
                isFinish: false 
            },
            dialogVisible: false,
            isLoading: true, 

            formData: {
                //--step_1--
                giftSum: '',
                currentCardDesign: null,
                designId: 1,
                //--step_2--
                giftType: "phone",
                recipientEmail: '',
                recipientPhone: '',
                cameOutName: '',
                sendersName: '',      
                congratulationText: '',
                dispatchDate: '',
                senderEmail: '',
            },

            senderPhoneNumber: '',
        }
    },
    computed: {
        ...mapGetters({
            giftCertificateBlock: 'giftCertificateBlock',
            giftCertificateOrder: 'giftCertificateOrder',
            authInfo: 'authInfo',
        }),
        getVerificationOfUser() {
            return !this.authInfo?.authStatus || (this.authInfo?.authStatus && !this.authInfo?.relations?.authUser?.data?.attributes?.isPhoneVerified)
        },
        controlVisibleStatus() {
            return this.currentStep.number < 3 && !(this.currentStep.number === 2 && this.getVerificationOfUser)
        },
        fieldSumValue() {
            return this.formData.giftSum ? `${this.renderPriceFormat(this.formData.giftSum)} ₽` : ``;
        }
    },
    methods: {
        ...mapActions({
            getGiftCertificateBlock: 'getGiftCertificateBlock',
            createGiftCertificateOrder: 'createGiftCertificateOrder'
        }),

        nextStep() {
            if(!this.startStepValidation()) return

            if(this.currentStep.number == 2) {
                this.submitSertificate()
            } else {
                this.currentStep = Object.assign(this.steps.find(s => s.number === this.currentStep.number + 1))
            }
        },

        previousStep() {
            this.currentStep = Object.assign(this.steps.find(s => s.number === this.currentStep.number - 1))
        },

        startStepValidation() {
            this.validationErrors = []

            const validationTemplate = this.validation.find(v => v.stepId === this.currentStep.id)
            if(!validationTemplate) return true

            validationTemplate.fields.forEach(vField => {
                if(vField.isRequired && !this.formData[vField.fieldName]) {
                    this.validationErrors.push({
                        fieldName: vField.fieldName,
                        message: `Обязательное поле`
                    })
                }
                if(vField.validationAction) {
                    const validationResult = vField.validationAction(this.formData[vField.fieldName])
                    if(validationResult) {
                        this.validationErrors.push({
                            fieldName: vField.fieldName,
                            message: validationResult
                        })
                    }
                }
            });

            this.validateEmailOrPhone();
            return !this.validationErrors.length
        },

        //TODO в дальнейшем нужно придумать триггер для данной валидации
        validateEmailOrPhone() {
            if(this.currentStep.number === 2) {
                if(this.formData.giftType === 'phone' && !this.formData.recipientPhone) {
                    this.validationErrors.push({
                        fieldName: "emailOrPhone",
                        message: `Обязательное поле`
                    })
                }
                if(this.formData.giftType === 'email' && !this.formData.recipientEmail) {
                    
                    this.validationErrors.push({
                        fieldName: "emailOrPhone",
                        message: `Обязательное поле`
                    })             
                } 
                if(this.formData.giftType === 'email' && this.formData.recipientEmail && !String(this.formData.recipientEmail).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    this.validationErrors.push({
                        fieldName: "emailOrPhone",
                        message: `Некорректный email`
                    })   
                }
            }
        },

        validationByCurrentTemplate(fieldName) {
            const currentStepForValidation = this.validation.find(v => v.stepId === this.currentStep.id)
            if(currentStepForValidation) {
                const vTemplate = currentStepForValidation.fields.find(field => field.fieldName === fieldName)
                if(vTemplate && vTemplate.validationAction) {
                    let validationResult = vTemplate.validationAction(this.formData[vTemplate.fieldName])
                    this.removeValidationErrByFieldName(vTemplate.fieldName)
                    if(validationResult) {
                        this.validationErrors.push({
                            fieldName: vTemplate.fieldName,
                            message: validationResult
                        })
                    }
                }
            }
        },

        removeValidationErrByFieldName(fieldName) {
            this.validationErrors = this.validationErrors.filter(err => err.fieldName !== fieldName)
        },

        isError(fieldName) {
            return this.validationErrors.find(vErr => vErr.fieldName === fieldName)
        },

        selectCardDesign(designId) {
            this.formData.currentCardDesign = Object.assign(this.cardDesigns.find(d => d.id === designId))
        },

        closePopup() {
            this.dialogVisible = false
        },

        renderPriceFormat(price) {
            return `${new String(price).replace(/\B(?=(?:\d{3})*$)/g, ' ')}`
        },

        sumFocusAction(field) {
            const finish = field.target.value.length - 2
            field.target.setSelectionRange(finish, finish)
        },

        fetchDesigns() {
            axios.get('/api/gift-certificates-design').then(res => {
                this.cardDesigns = res.data
                this.formData.currentCardDesign = res.data[0]
                this.isLoading = false
            })
        },

        sumUpdate(field) {
            field.preventDefault()
            
            if(field.key === 'Backspace') {
                this.formData.giftSum = this.formData.giftSum.slice(0, -1)
            } else {
                if(['0','1','2','3','4','5','6','7','8','9'].includes(field.key)) {
                    this.formData.giftSum = this.formData.giftSum + field.key
                }
            }

            this.sumFocusAction(field)
        },

        async getUserAuthData() {
            await this.$store.dispatch('getAuthStatus')
            this.formData.senderEmail = this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail
                ? this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail?.data?.attributes?.email
                : this.authInfo?.relations?.authUser?.data?.relations?.currentMarketingEmail?.data?.attributes?.email
            this.senderPhoneNumber = this.authInfo?.relations?.authUser?.data?.attributes?.phone
        },

        async submitSertificate() {
            let request = {
                senderName: this.formData.sendersName,
                recipientName: this.formData.cameOutName,
                sendDatetime: this.formData.dispatchDate,
                messageToRecipient: this.formData.congratulationText,
                giftType: this.formData.giftType,
                designId: this.formData.currentCardDesign.id,
                amount: parseInt(this.formData.giftSum),

                senderEmail: this.formData.senderEmail
            }

            if(request.giftType === 'email') {
                request.recipientEmail = this.formData.recipientEmail
            }

            if(request.giftType === 'phone') {
                request.recipientPhone = this.formData.recipientPhone.replace(/\s+/g, '')
            }

            this.isLoading = true;

            await this.createGiftCertificateOrder(request).then(() => {
                window.location.href = this.giftCertificateOrder.data.relationships.payments.data[0].data.attributes.invoice_url;
                this.isLoading = false;
            }).catch((err) => {
                this.isLoading = false;
            })
        }
    },
    async mounted() {
        this.fetchDesigns()
        await this.$store.dispatch('getGiftCertificateBlock', '1');
        await this.getUserAuthData();
    }
}
</script>

<style scoped lang="scss">
    p {
        line-height: 17.95px;
    }

    .back-btn {
        position: absolute;
        top: 20px;
        left: 20px;
        cursor: pointer;
    }

    .steps_inner {
        width: 392px;
    }

    .custom-checkbox {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        margin-bottom: 2px;
    }

    .custom-checkbox:checked {
        background-color: #000;
        background-image: none;
    }

    .input-defailt {
        width: 100%;
        border-color: #D9D9D9;
        padding-top: 6px;
        padding-bottom: 6px;
        font-size: 14px;
    }

    .input-defailt::placeholder {
        color: #8e8e8e;
    }

    .field-row_error {
        margin-top: 2px;
        font-size: 12px;
        color: #C34040A6;
    }

    .sum-list_wrapper {
        width: 100%;
        margin-bottom: -10px;
    }

    .design-list_item {
        display: inline-block;
        width: 62px;
        height: 39px;
        margin-right: 20px;
        cursor: pointer;
        overflow: hidden;
        box-sizing: border-box;
        border: 1px solid transparent;
    }

    .design-list_item:nth-child(5n) {
        margin-right: 0px;
    }

    .design-list_item.active {
        border: 1px solid #372f2f;
    }

    .sum-list_item {
        color: #8e8e8e;
        cursor: pointer;
        display: inline-block;
        border: 1px solid #D9D9D9;
        margin-right: 8px;
        margin-bottom: 10px;
        padding: 7.5px 14px;
        font-size: 14px;
    }
    .sum-list_item.active {
        color: #000;
        border-color: #000;
    }

    .vue-tel-input {
        height: 38px;
        border: 1px solid #d9d9d9 !important;
    }


    @media screen and (min-width: 600px){
        .sum-list_item {
            width: 125px;
            text-align: center;
        }
        .sum-list_item:nth-child(3n) {
            margin-right: 0px;
        }  
    }

    @media screen and (max-width: 600px) {
        .steps_inner {
            width: 100%;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }

        .design-list_item {
            margin-right: 10px;
        }

        .sum-list_wrapper {
            overflow-x: scroll;
        }
        .sum-list_inner {
            width: 610px;
            padding-left: 15px;
        }
        .sum-list_wrapper::-webkit-scrollbar {
            display: none;
        }
        .sum-list_wrapper {
            -ms-overflow-style: none;
            scrollbar-width: none;
            position: relative;
        }

        .sum-list_fade-gradient {
            position: relative;
            margin-right: -15px;
            margin-left: -15px;
        }
        .sum-list_fade-gradient::after {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            background: linear-gradient(to left, rgba(230, 100, 101, 0), #ffffff);
            width: 20px;
            height: 100%;
        }
        .sum-list_fade-gradient::before {
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            background: linear-gradient(to right, rgba(230, 100, 101, 0), #ffffff);
            width: 45px;
            height: 100%;
            z-index: 100;
        }
        
    }

    @media screen and (max-width: 420px) {
        .steps_inner {
            max-width: 392px;
        }
    }

</style>
