import { createRouter, createWebHistory } from 'vue-router';

import MainPage from "./views/MainPage";
import CareGuideForProductsPage from "./views/CareGuideForProductsPage";
import Product from "./views/Product";
import Category from "./views/Category";
import Info from "./views/Info";
import Catalog from "./views/Catalog";
import SmsTracking from "./views/SmsTracking";
import Lookbook from "./views/Lookbook";
import Search from "./views/Search";
import NotFound from "./views/errors/NotFound";
import Account from "./views/Account";
import GiftCertificate from "./views/GiftCertificate";
import Cart2 from "./views/Cart2.vue";
import PreorderCart from "./views/cart/PreorderCart.vue";
import OrderCreatedSuccessfullyPage from "./views/OrderCreatedSuccessfullyPage";
import CartCheckout from "./views/CartCheckout";
import InfoBlock from "./components/InfoBlock";
import AllProducts from "./views/AllProducts";
import LookbookBlock from "./components/LookbookBlock";
import LookbookWomenStoriesBlock from "./components/LookbookWomenStoriesBlock";
import FontsTest from "./views/FontsTest";
import ChangePhone from "./views/ChangePhone.vue";
import Test from "./views/Test";
import Register from "./components/Auth/Register";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import VueDadataGranular from "./components/Cart/DeliveryCartBlock";
import KeyBoardSelection from "./views/KeyboardSelection";
import Payment from "./views/Payment";
import EmailSubscriptionPage from "./views/EmailSubscriptionPage";
import EmailForCorpClientsSubscriptionPage from "./views/EmailForCorpClientsSubscriptionPage";
import EkbEmailSubscriptionPage from "./views/EkbEmailSubscriptionPage";
import OfflineThanksPage from "./views/mindbox_widgets/OfflineThanksPage";
import Nps from "./views/Feedback/Nps";
import ContactsPage from "./views/InfoPages/ContactsPage";
import DeliveryAndReturnPage from "./views/InfoPages/DeliveryAndReturnPage";
import PromoPage from "./views/PromoPage";
import StylistsDiscountLendingMindboxPage from "./views/mindbox_widgets/StylistsDiscountLendingMindboxPage";
import StylistsCashbackLendingMindboxPage from "./views/mindbox_widgets/StylistsCashbackLendingMindboxPage";
import OfflineAfimollLendingMindboxPage from "./views/mindbox_widgets/OfflineAfimollLendingMindboxPage";
import OfflineShopComingSoonLendingMindboxPage from "./views/mindbox_widgets/OfflineShopComingSoonLendingMindboxPage";
import OfflineAviaparkLendingMindboxPage from "./views/mindbox_widgets/OfflineAviaparkLendingMindboxPage";
import OfflineOkeaniaLendingMindboxPage from "./views/mindbox_widgets/OfflineOkeaniaLendingMindboxPage";
import OfflineShowroomMskLendingMindboxPage from "./views/mindbox_widgets/OfflineShowroomMskLendingMindboxPage";
import OfflineAngleterLendingMindboxPage from "./views/mindbox_widgets/OfflineAngleterLendingMindboxPage";
import OfflineTsvetnoyLendingMindboxPage from "./views/mindbox_widgets/OfflineTsvetnoyLendingMindboxPage";
import AdvertisementLendingMindboxPage from "./views/mindbox_widgets/AdvertisementLendingMindboxPage";
import HasWebPushNoMailLendingMindboxPage from "./views/mindbox_widgets/HasWebPushNoMailLendingMindboxPage";
import OfflineEkbLendingMindboxPage from "./views/mindbox_widgets/OfflineEkbLendingMindboxPage";
import UnsubscribeLendingMindboxPage from "./views/mindbox_widgets/UnsubscribeLendingMindboxPage";
import ClientInfoLendingMindboxPage from "./views/mindbox_widgets/ClientInfoLendingMindboxPage";
import EmailForSaleLendingMindboxPage from "./views/mindbox_widgets/EmailForSaleLendingMindboxPage";
import InstagramLendingPage from "./views/InstagramLendingPage";
import LivePage from "./views/LivePage";
import LookbookWomenStories from "./views/LookbookWomenStories.vue";
import Documents from "./views/Documents.vue";
import GiftCertificateNew from './views/GiftCertificateNew.vue';
import AppLanding from './views/AppLanding.vue';

import axios from '@/api';


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
        path: "/payment/:status",
        name: "Payment",
        component: Payment,
    },
    {
        path: "/keyboard-selection",
        name: "KeyBoardSelection",
        component: KeyBoardSelection,
    },
    {
        path: "/app",
        name: "AppLanding",
        component: AppLanding,
    },
    {
        path: "/vue-dadata-granular",
        name: "VueDadataGranular",
        component: VueDadataGranular,
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
    } /*
    {
        path: '/login',
        component: Login,
        name: 'Login'
    },*/,
    {
        path: "/password/forgot",
        component: ForgotPassword,
        name: "ForgotPassword",
    },
    {
        path: "/password/reset",
        component: ResetPassword,
        name: "ResetPassword",
    },
    {
        path: "/account",
        name: "Account",
        component: Account,
    },
    {
        path: "/fonts-test",
        name: "FontsTest",
        component: FontsTest,
    },
    {
        path: "/",
        name: "MainPage",
        component: MainPage,
    },
    {
        path: "/catalog",
        name: "Catalog",
        component: Catalog,
    },
    {
        path: "/promo",
        name: "PromoPage",
        component: PromoPage,
    },
    {
        path: "/s",
        name: "SmsTracking",
        component: SmsTracking,
    },
    {
        path: "/documents",
        name: "Documents",
        component: Documents,
    },
    {
        path: "/catalog/all",
        name: "AllProducts",
        component: AllProducts,
    },
    {
        path: "/catalog/:category",
        name: "Category",
        component: Category,
    },
    {
        path: "/catalog/:category/:product",
        name: "Product",
        component: Product,
    },
    {
        path: "/info",
        name: "Info",
        component: Info,
    },
    {
        path: "/info/:info",
        name: "InfoBlock",
        component: InfoBlock,
    },
    {
        path: "/lookbook",
        name: "Lookbook",
        component: Lookbook,
    },
    {
        path: "/lookbook/women-to-know",
        name: "LookbookWomenStories",
        component: LookbookWomenStories,
    },
    {
        path: "/lookbook/:lookbook",
        name: "LookbookBlock",
        component: LookbookBlock,
    },
    {
        path: "/lookbook/women-to-know/:lookbook",
        name: "LookbookWomenStoriesBlock",
        component: LookbookWomenStoriesBlock,
    },
    {
        path: "/search",
        name: "Search",
        component: Search,
    },
    {
        path: "/test",
        name: "Test",
        component: Test,
    },
    {
        path: "/cart",
        name: "Cart2",
        component: Cart2,
    },
    {
        path: "/preorder-cart",
        name: "PreorderCart",
        component: PreorderCart,
    },
    {
        path: "/order-created-pay-next",
        name: "OrderCreatedSuccessfullyPage",
        component: OrderCreatedSuccessfullyPage,
    },
    {
        path: "/cart-checkout",
        beforeEnter: (to, form, next) => {
            axios
                .get("/api/cart")
                .then((res) => {
                    if (res.data.data.attributes.itemsQuantity > 0) {
                        next();
                    } else {
                        return next({ path: "/404" });
                    }
                })
                .catch(() => {
                    return next({ redirect: "/404" });
                });
        },
        name: "CartCheckout",
        component: CartCheckout,
    },
    {
        path: "/gift-certificate",
        name: "GiftCertificate",
        component: GiftCertificateNew,
    },
    {
        path: "/gift-certificate-new",
        name: "GiftCertificateNew",
        component: GiftCertificateNew,
    },
    {
        path: "/change/phone",
        name: "ChangePhone",
        component: ChangePhone,
    },
    {
        path: "/success-subscription",
        component: MainPage,
        props: { successSubscriptionFromPopup: true },
    },
    {
        path: "/email",
        name: "EmailSubscriptionPage",
        component: EmailSubscriptionPage,
    },
    {
        path: "/email-for-corp",
        name: "EmailForCorpClientsSubscriptionPage",
        component: EmailForCorpClientsSubscriptionPage,
    },
    {
        path: "/ekb",
        name: "EkbEmailSubscriptionPage",
        component: EkbEmailSubscriptionPage,
    },
    {
        path: "/feedback/nps",
        name: "Nps",
        component: Nps,
    },
    {
        path: "/contacts",
        name: "ContactsPage",
        component: ContactsPage,
    },
    {
        path: "/delivery-and-return",
        name: "DeliveryAndReturnPage",
        component: DeliveryAndReturnPage,
    },
    {
        path: "/lending-offline-afimoll",
        name: "OfflineAfimollLendingMindboxPage",
        component: OfflineAfimollLendingMindboxPage,
    },
    {
        path: "/stylists-10-discount",
        name: "StylistsDiscountLendingMindboxPage",
        component: StylistsDiscountLendingMindboxPage,
    },
    {
        path: "/stylists-cashback",
        name: "StylistsCashbackLendingMindboxPage",
        component: StylistsCashbackLendingMindboxPage,
    },
    {
        path: "/offline-shop-coming-soon",
        name: "OfflineShopComingSoonLendingMindboxPage",
        component: OfflineShopComingSoonLendingMindboxPage,
    },
    {
        path: "/lending-offline-aviapark",
        name: "OfflineAviaparkLendingMindboxPage",
        component: OfflineAviaparkLendingMindboxPage,
    },
    {
        path: "/lending-offline-okeania",
        name: "OfflineOkeaniaLendingMindboxPage",
        component: OfflineOkeaniaLendingMindboxPage,
    },
    {
        path: "/lending-offline-tsvetnoy",
        name: "OfflineTsvetnoyLendingMindboxPage",
        component: OfflineTsvetnoyLendingMindboxPage,
    },
    {
        path: "/email-site",
        name: "EmailForSaleLendingMindboxPage",
        component: EmailForSaleLendingMindboxPage,
    },
    {
        path: "/lending-offline-showroom-msk",
        name: "OfflineShowroomLendingMskMindboxPage",
        component: OfflineShowroomMskLendingMindboxPage,
    },
    {
        path: "/lending-get-mail",
        name: "HasWebPushNoMailLendingMindboxPage",
        component: HasWebPushNoMailLendingMindboxPage,
    },
    {
        path: "/lending-advert",
        name: "AdvertisementLendingMindboxPage",
        component: AdvertisementLendingMindboxPage,
    },
    {
        path: "/lending-offline-angleter",
        name: "OfflineAngleterLendingMindboxPage",
        component: OfflineAngleterLendingMindboxPage,
    },
    {
        path: "/lending-offline-ekb",
        name: "OfflineEkbLendingMindboxPage",
        component: OfflineEkbLendingMindboxPage,
    },
    {
        path: "/unsubscribe",
        name: "UnsubscribeLendingMindboxPage",
        component: UnsubscribeLendingMindboxPage,
    },
    {
        path: "/client-info",
        name: "ClientInfoLendingMindboxPage",
        component: ClientInfoLendingMindboxPage,
    },
    {
        path: "/lending-inst-20",
        name: "InstagramLendingPage",
        component: InstagramLendingPage,
    },
    {
        path: "/thanks-offline",
        name: "OfflineThanksPage",
        component: OfflineThanksPage,
    },
    {
        path: "/guide/sarafan-camilla",
        name: "CareGuideForProductsPage",
        component: CareGuideForProductsPage,
    },
    {
        path: "/live",
        name: "LivePage",
        component: LivePage,
    },
    {
        path: "/404",
        name: "NotFound",
        component: NotFound,
        meta: { title: "Not Found" },
    },
    /*{
        path: "*",
        redirect: "/404",
    },*/
  ]
});

export default router;