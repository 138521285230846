<template>
    <div>
        <form class="w-full">
            <div class="subscribe-form__row flex items-baseline">
                <input class="subscribe-input focus:border-black"
                    type="text"
                    placeholder="example@mail.com"
                    aria-label="E-mail"
                    name="email"
                    v-model="email"
                    @focus="() => { descIsVisible = true }"
                    @blur="() => { descIsVisible = false }"
                >
                <button class="subscribe-btn" type="button" @click="subscribeFromFooter(email)"><img :src="`/resources/images/header/next-cursor.svg`"/></button>
            </div>
            <div class="mt-2">
                <span class="text-xs text-gray-main" v-if="subscribeEmailStatus=='loading'">
                    Оформляем подписку...
                </span>
                    <span class="text-xs text-green-500"  v-if="subscribeEmailStatus=='success'">
                    Успех!
                </span>
                    <span class="text-xs text-red-500" v-if="subscribeEmailStatus=='error'">
                    Ошибка  
                </span>
            </div>
            <div class="mt-2 text-xs tracking-normal text-gray-light" :style="{opacity: descIsVisible ? 1 : 0}">
                Оставляя свой email, вы соглашаетесь на обработку своих персональных данных и подтверждаете, что ознакомлены с политикой <router-link class="underline" to="/info/privacy-policy" target="_blank">конфиденциальности</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default { 
    name: 'SubscribeForm',
    data() {
        return {
            descIsVisible: false
        }
    },
    methods: {
        ...mapActions({
            setSubscribeEmail: 'setSubscribeEmail',
            subscribe: 'subscribe',
            fetchFooterSubscription: 'fetchFooterSubscription',
        }),
        subscribeFromFooter(email) {
            this.subscribe({
                'email' : email,
                'source' : 'footer',
            });
            this.$gtm.trackEvent({
                event: "subscribeFromFooter",
                clientEmail: email,
            });
        }
    },
    computed: {
        ...mapGetters({
            subscribeEmail: 'subscribeEmail',
            subscribeEmailStatus: 'subscribeEmailStatus',
            footer: 'footer',
            footerStatus: 'footerStatus',
            footerSubscription: 'footerSubscription',
            footerSubscriptionStatus: 'footerSubscriptionStatus',
        }),
        email: {
            get(){
                return this.subscribeEmail;
            },
            set(subscribeEmail){
                this.setSubscribeEmail(subscribeEmail);
            }
        },
    },
    mounted () {
        this.$store.dispatch('fetchFooterSubscription', 1);
    },
}
</script>

<style lang="scss" scoped>
    .subscribe-form {
        &__row {
            width: 314px;
            position: relative;
        }
    }

    .subscribe-input  {
        font-size: 14px;
        border: 1px solid #d9d9d9;
        width: 100%;
        padding: 10px 22px;
    }

    .subscribe-btn {
        position: absolute;
        right: 15px;
        top: 18px;
    }

    @media screen and (max-width: 600px) {
        .header__sub-menu__subscribe-form {
            width: 100%;
        } 

        .subscribe-form__row {
            width: 100%;
        }
    }
</style>
