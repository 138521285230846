<template>
    <div class="flex flex-col gap-3">
        <div v-if="getVerificationOfUser">
            <input v-model="phone"
                   id="phone" readonly
                   class="mt-2 appearance-none text-11 border outline-none
                         border-gray-lighter text-gray-light rounded-none w-full h-9 px-2
                       focus:outline-none focus:border-black lg:text-13 no-spinner">
        </div>
        <div v-else>
            <vue-tel-input
                v-model="phone"
                :inputOptions="{ placeholder: 'Номер телефона' }"
                @focus="resetPhoneInput"
                :preferredCountries="['ru', 'by', 'kz']"
                :defaultCountry="'ru'"
                :mode="'international'"
                :required="true"
                :styleClasses="styleClasses"/>
            <p v-if="phone && v$.phone.$invalid" class="text-11 text-red-light-2">
                {{ "Пожалуйста, введите корректный номер телефона" }}
            </p>
        </div>

        <input v-if="isCodeSend"
               v-model="code"
               type="text"
               maxlength="4"
               placeholder="Введите код из СМС"
               class="appearance-none text-11 text-gray-light rounded-none w-full h-9 px-2
             focus:outline-none focus:border-black lg:text-13 no-spinner"/>

        <p v-if="authWayErrorMessage" class="text-11 text-red-light-2">
            {{ authWayErrorMessage }}
        </p>
        <p v-if="isButtonDisabled" class="resend-message text-11">
            Вы можете повторно отправить код через {{ timeLeft }} секунд</p>
        <div v-if="needCaptcha">
            <img v-bind:src="captchaImg" alt=""/>
            <input
                v-model="captchaCode"
                type="text"
                maxlength="4"
                placeholder="Введите код c картинки"
                class="appearance-none text-11 text-gray-light rounded-none w-full h-9 px-2
             focus:outline-none focus:border-black lg:text-13 no-spinner"
            />
            <button class="w-full mt-2 h-10 py-1 px-5 text-white r focus:outline-none text-14 border border-black bg-black" @click="refreshCapcha()">Обновить capcha</button>
        </div>
        <button v-if="!isCodeSend"
                type="button"
                class="w-full mt-2 h-10 py-1 px-5 text-white r focus:outline-none text-14"
                :class="isPhoneValid && timeLeft === 0 && !initServerError
                        ? 'border border-black bg-black'
                        : 'border border-gray-light-2 bg-gray-light-2'"
                @click="getCode()">
            {{ "Получить код" }}
        </button>
        <button v-else-if="isCodeSend && isPhoneValid && attemptsLeft > 0"
                type="button"
                class="w-full mt-2 h-10 py-1 px-5 text-white r focus:outline-none text-14"
                :class="isPhoneValid && !authServerError
                        ? 'border border-black bg-black'
                        : 'border border-gray-light-2 bg-gray-light-2'"
                @click="authorizeByCredentials()">
            {{ "Войти" }}
        </button>
        <button v-if="resendLeft > 0 && timeLeft === 0"
                class="w-full mt-2 h-10 py-1 px-5 text-green-dark r focus:outline-none text-14"
                @click="getCode()">
            Получить код повторно
        </button>
    </div>
</template>

<script>
import axios from '@/api'
import { mapActions, mapGetters } from 'vuex'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

var SHA256 = require("crypto-js/sha256");

export default {
    name: 'AuthByPhone',
    props: {},
    data() {
        return {
            phone: null,
            authWay: null,
            isEndTimeCode: false,
            isCodeSend: false,
            attemptsLeft: null,
            resendLeft: null,
            authServerError: false,
            initServerError: false,
            code: null,
            authWayErrorMessage: null,
            isButtonDisabled: false,
            needCaptcha: false,
            captchaKey: null,
            captchaImg: '2',
            captchaCode: null,
            timeLeft: 0,
        }
    },
    computed: {
        getVerificationOfUser() {
            return this.authInfo?.authStatus
                && this.authInfo?.relations?.authUser?.data?.relations?.userPhone
                && this.authInfo?.relations?.authUser?.data?.relations?.userPhone?.data?.attributes?.phone
                && !this.authInfo?.relations?.authUser?.data?.attributes?.isPhoneVerified
        },
        styleClasses() {
            if (!!this.phone && this.v$.phone.$invalid) {
                return (
                    "vue-tel-input-error appearance-none rounded-none w-full h-10 pl-4 mt-3 text-body-18 font-normal " +
                    " text-11 text-gray-light  w-full h-9 focus:border px-2\n" +
                    "                          focus:border-red lg:text-13"
                );
            } else {
                return (
                    "appearance-none rounded-none w-full h-10 pl-4 mt-3 text-body-18 font-normal" +
                    " text-11 text-gray-light w-full h-9 focus:border px-2\n" +
                    "                          focus:border-black lg:text-13"
                );
            }
        },
        isPhoneValid() {
            return !!this.phone && !this.v$.phone.$invalid
        },
        ...mapGetters({
            user: 'user',
            authStatus: 'authStatus',
            authInfo: 'authInfo',
        })
    },
    setup () { return { v$: useVuelidate() } },
    validations() {
        return {
            phone: {
                required,
                regex: function (value) {
                    this.phone = value.replace(/^8/gim, "+7")
                    const resultValidate = value
                        ? /(^\+7[0-7,9]\d{9}$)|(^\+([0-6]|[8-9])\d{6,14}$)/i.test(
                            value
                                .replace(/^8/gim, "+7")
                                .replaceAll(/\s*/gim, ""),
                        )
                        : true;
                    if (!resultValidate) this.v$.$touch();
                    return resultValidate;
                },
            },
        };
    },
    methods: {
        resetPhoneInput() {
            this.isCodeSend = false
            this.code = null
        },
        async hashString(input) {
            return SHA256(input); //createHash('sha256').update(input, 'utf8').digest('hex');
        },
        async getCode() {
            this.v$.$touch();
            if (this.authInfo?.authStatus && this.timeLeft === 0 && !this.initServerError) {
                await axios.post('/api/verification/phone/init', {'phone': (this.phone.replaceAll(/\s*/gim, ""))})
                    .then(res => {
                        this.code = null
                        this.authWayErrorMessage = null
                        this.startTimer(90);
                        this.isCodeSend = true
                        this.attemptsLeft = res.data?.data?.attributes?.attemptsLeft
                        this.resendLeft = res.data?.data?.attributes.resendLeft
                    })
                    .catch(error => {
                        this.checkTheError(error?.response?.data?.errors && error?.response?.data?.errors?.phone
                            ? error?.response?.data?.errors.phone[0]
                            : error?.response?.data?.message)
                        this.initServerError = error?.response?.status === 500
                    });
            } else if (this.phone && !this.v$?.phone?.$invalid && this.timeLeft === 0 && !this.initServerError) {
                this.authServerError = false
                let date = new Date().toISOString().slice(0, 10).replace(/-/g, '-')
                let hashDate = await this.hashString(date)
                let phone = this.phone
                let loginData = {'login': (phone.replaceAll(/\s*/gim, ""))}
                if (this.needCaptcha) {
                    loginData.captcha = this.captchaCode
                    loginData.key = this.captchaKey
                }
                await axios.post(
                    '/api/auth/init',
                    loginData,
                    {
                        headers: {
                            'Request-Timer': hashDate,
                            'Content-Type': 'application/json;charset=UTF-8',
                        }
                    }
                )
                    .then(res => {
                        if (res.data?.message === 'need capcha') {
                            this.needCaptcha = true
                            this.getCapcha()
                        } else {
                            this.code = null
                            this.authWayErrorMessage = null
                            this.startTimer(90);
                            this.isCodeSend = true
                            this.attemptsLeft = res.data?.data?.attributes?.attemptsLeft
                            this.resendLeft = res?.data?.data?.attributes?.resendLeft
                            this.needCaptcha = false
                        }
                    })
                    .catch(error => {
                        this.checkTheError(error?.response?.data?.errors && error?.response?.data?.errors?.password
                            ? error?.response?.data?.errors?.password[0]
                            : error?.response?.data?.message)
                        this.initServerError = error?.response?.status === 500
                    });
            }
        },
        ...mapActions({
            getCart: 'getCart'
        }),
        refreshCapcha() {
            this.getCapcha()
        },
        getCapcha() {
            axios.get('/api/auth/capcha').then(res => {
                this.captchaKey = res.data?.key
                this.captchaImg = res.data?.img
                this.authWayErrorMessage = false
                this.initServerError = false
            }).catch(() => {
                this.authWayErrorMessage = 'Произошла ошибка. Обратитесь, пожалуйста, в поддержку'
                this.authWayErrorMessage = false
                this.initServerError = false
            })
        },
        checkTheError(error) {
            this.authWayErrorMessage = null
            const errorMessagesMap = {
                'user not found': 'Пользователь не найден',
                'Verification code is invalid': 'Введен некорректный код',
                'validation.required': 'Введите код',
                'The phone is already exist.': 'Данный номер телефона привязан к другому аккаунту, обратитесь пожалуйста в поддержку',
                'Verification has not started': 'Вы использовали все попытки проверки кода, перегенерируйте код, либо ' +
                    'обратитесь в техническую поддержку',
                'The provided credentials are incorect': 'Введен неккоректный пароль',
                'You cant resend the code again': 'Вы использовали все доступные попытки входа.' +
                    ' Пожалуйста, попробуйте позднее или обратитесь в техническую поддержку',
            };
            this.authWayErrorMessage = errorMessagesMap[error] || 'Произошла ошибка. Обратитесь, пожалуйста, в поддержку'
        },
        authorizeByCredentials() {
            if (this.authInfo?.authStatus && !this.authInfo?.relations.authUser?.data?.relations?.userPhone?.data?.attributes?.verificationStatus) {
                axios.post('/api/verification/phone/verify', {
                    'phone': (this.phone.replaceAll(/\s*/gim, "")),
                    'code': this.code
                }).then(res => {
                    this.isButtonDisabled = false
                    this.$emit('authByPhoneSuccessClosePopup')
                }).catch(error => {
                    this.checkTheError(error?.response?.data.errors && error?.response?.data?.errors?.password
                        ? error?.response?.data?.errors?.password[0]
                        : error?.response?.data?.message)
                    this.authServerError = error?.response?.status === 500
                })
            } else if (!this.authServerError) {
                axios.post('/api/auth/login', {
                    'login': (this.phone.replaceAll(/\s*/gim, "")),
                    'password': this.code
                })
                    .then(() => {
                        this.isButtonDisabled = false
                        this.$emit('authByPhoneSuccessClosePopup')
                        this.$store.commit('setAlertShow', true)
                        this.$store.commit('setAlertMessage', {
                            message: 'Вы успешно авторизовались',
                        })
                    })
                    .catch(error => {
                        this.checkTheError(error?.response?.data?.errors && error?.response?.data?.errors?.password
                            ? error?.response?.data?.errors?.password[0]
                            : error?.response?.data?.message)
                        this.authServerError = error?.response?.status === 500
                    })
            }
        },

        startTimer(duration) {
            this.isButtonDisabled = true;
            this.timeLeft = duration;
            const endTime = new Date(new Date().getTime() + duration * 1000);
            localStorage.setItem('endTime', endTime);

            const timer = setInterval(() => {
                this.timeLeft--;
                if (this.timeLeft <= 0) {
                    clearInterval(timer);
                    this.isButtonDisabled = false;
                    localStorage.removeItem('endTime');
                }
            }, 1000)
        },
        async submit() {
            await this.getCart()

            await this.$gtm.trackEvent({
                event: "login",
                clientId: this.user.id,
                clientEmail: this.user.email,
                clientPhone: this.user.phone,
                noninteraction: false,
            });
        }
    },
    mounted() {
        this.$store.dispatch('getAuthStatus')
        this.phone = !this.authInfo?.relations.authUser?.data.relations.userPhone.data.attributes.verificationStatus
            ? this.authInfo?.relations.authUser?.data.relations.userPhone.data.attributes.phone
            : this.phone
        const endTime = localStorage.getItem('endTime');
        if (endTime) {
            const remainingTime = Math.floor((new Date(endTime) - new Date()) / 1000);
            if (remainingTime > 0) {
                this.startTimer(remainingTime);
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.placeholder {
    color: #8E8E8E;
}
.vue-tel-input {
    height: 38px;
}
button {
    height: 38px !important;
    margin-top: 0px !important;
}
.resend-message {
    font-size: 14px;
    color: #8E8E8E;
    text-align: center;
}
</style>
