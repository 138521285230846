<template>
    <div class="top-12 sticky sm:top-15 bg-white " :class="isFiltersPopupActive ? 'z-30' : 'z-20'">
        <div class="filter-control__wrapper">
            <div class="filter-control__categories no-scrollbar sticky">
                <FiltersSubCategories @filterBySubCategory="filterBySubCategory"
                    :filteredProducts="filteredProducts"
                    :availableFiltersForCategoryLocal="availableFiltersForCategoryLocal"
                    :categoryId="categoryId"
                    :countRemainderStocks="getCountRemainder"
                    :categoryTitle="categoryTitle"/>
            </div>
            <div class="filter-control__dialog-control">
                <button @click="openFiltersPopup"
                        class="flex uppercase text-14 text-black tracking-wider cursor-pointer focus:outline-none"
                        v-if="getCountRemainder" >
                    Фильтры
                    <span class="ml-1" v-if="quantityOfAppliedFilters">({{ quantityOfAppliedFilters }})</span>
                    <svg class="ml-[10px] mt-[7px]" width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.5 5.5L10 1" stroke="black" stroke-linecap="square"/>
                        <path d="M1 1.00037L5.5 5.50037" stroke="black" stroke-linecap="square"/>
                    </svg>
                </button>
            </div>
        </div>
        <Filters v-if="isFiltersPopupActive"
                 :isFiltersActive="isFiltersPopupActive"
                 :category-id="categoryId"
                 :sortBy="sortBy"
                 @applyFilters="applyFilters"
                 @resetColorFilter="resetColorFilter"
                 @resetSizeFilter="resetSizeFilter"
                 @resetStockFilter="resetStockFilter"
                 @resetAllFilters="resetAllFilters"
                 @successFiltration="filteredProducts2"
                 @closeFilterPopup="closeFilterAndSortingPopup"
                 @putTypeOfSortingIntoSortingAndFiltration="putTypeOfSortingIntoSortingAndFiltration"
                 />

        <div v-if="filteredProducts?.data && filteredProducts?.data.length === 0 && filteredStatus !== 'loading'">
            <NoProductsByFilters @resetAllFiltersAndSorting="resetAllFiltersAndSorting"
                                 :payload="payload"/>
        </div>
    </div>
</template>

<script>
import Sorting from "./Sorting.vue";
import {mapActions, mapGetters} from "vuex";
import Filters from "./Filters.vue";
import FiltersSubCategories from "./FiltersSubCategories.vue";
import NoProductsByFilters from "./NoProductsByFilters.vue";

export default {
    name: 'FiltersAndSorting',
    components: {NoProductsByFilters, FiltersSubCategories, Filters, Sorting},
    data: () => ({
        isFiltersActive: false,
        isSortPopupActive: false,
        isFiltersPopupActive: false,
        action: null,
        items: [],
        currentPage: 1,
        perPage: 20,
        allLoaded: false,
        loading: false,
        savedScrollPosition: 0,
    }),
    props: {
        categoryId: null,
        categoryTitle: null
    },
    computed: {
        ...mapGetters({
            payload: 'payload',
            minValue: 'minValue',
            maxValue: 'maxValue',
            filteredStatus: "filteredStatus",
            rangeOfPricesValue: 'rangeOfPricesValue',
            filteredProducts: 'filteredProducts',
            availableFiltersForCategoryLocal: 'availableFiltersForCategoryLocal'
        }),
        quantityOfAppliedFilters() {
            const {colors, sizes, stocks} = this.payload.filter;
            let isPriceFilterApplied = this.filteredProducts?.meta.currentFilters?.priceFrom > this.minValue
                || this.filteredProducts?.meta.currentFilters?.priceTo < this.maxValue
            return isPriceFilterApplied ? [colors, sizes, stocks].filter(arr => arr.length > 0).length + 1
                : [colors, sizes, stocks].filter(arr => arr.length > 0).length;
        },
        sortBy() {
            return this.payload.sort
        },
        getCountRemainder() {
            return this.categoryId !== 15 && this.categoryId !== 196;
        },
    },
    methods: {
        ...mapActions({
            sortAndFilter: 'sortAndFilter',
            putCategoriesIntoFilter: 'putCategoriesIntoFilter',
            setRangeOfPricesValue: 'setRangeOfPricesValue',
            cleanAllFilters: 'resetAllFilters',
            putTypeOfSortingIntoPayload: 'putTypeOfSortingIntoPayload',
            cleanPayloadFilterColors: 'cleanPayloadFilterColors',
            cleanPayloadFilterSizes: 'cleanPayloadFilterSizes',
            cleanPayloadFilterStocks: 'cleanPayloadFilterStocks',
            resetIsEndStatus: 'resetIsEndStatus',

        }),
        async resetAllFiltersAndSorting() {
            await this.cleanAllFilters()
            this.resetIsEndStatus()
            await this.applyFiltersAndSorting()
        },
        filteredProducts2() {
            this.$emit('successFiltration', {products: this.filteredProducts})
        },
        openFiltersPopup() {
            this.isFiltersPopupActive = true
        },
        openSortingPopup() {
            this.isSortPopupActive = !this.isSortPopupActive
        },
        filterBySubCategory(data) {
            this.putCategoriesIntoFilter(data.id)
            this.resetIsEndStatus()
            this.applyFiltersAndSorting()
        },
        sort(data) {
            this.putTypeOfSortingIntoPayload(data.sort)
            this.resetIsEndStatus()
            this.applyFiltersAndSorting()
        },

        applyFilters(data) {
            this.action = null
            data.action === 'reset' ? this.action = 'reset' : null
            this.resetIsEndStatus()
            this.applyFiltersAndSorting()
        },
        resetColorFilter(data) {
            this.cleanPayloadFilterColors()
            this.applyFilters(data)
        },
        resetSizeFilter(data) {
            this.cleanPayloadFilterSizes()
            this.applyFilters(data)
        },
        resetStockFilter(data) {
            this.cleanPayloadFilterStocks()
            this.applyFilters(data)
        },
        resetAllFilters(data) {
            this.cleanAllFilters()
            this.applyFilters(data)
        },
        putTypeOfSortingIntoSortingAndFiltration(data) {
            this.putTypeOfSortingIntoPayload(data.type)
            this.resetIsEndStatus()
            this.applyFiltersAndSorting()
        },
        async applyFiltersAndSorting() {
            await this.sortAndFilter(Number(this.categoryId))
            await this.$emit('applyFiltersAndSorting', {products: this.filteredProducts})
            if (this.action !== 'reset') {
                this.isFiltersPopupActive = false
                this.isSortPopupActive = false
            }
        },
        closeFilterAndSortingPopup(data) {
            this.isFiltersPopupActive = data.isFiltersPopupActive;
            this.isSortPopupActive = data.isSortPopupActive;
        }
    },
    mounted() {
        this.applyFiltersAndSorting();
    },
    watch: {
        categoryId() {
            if (this.payload.filter.mainCategoryId !== this.categoryId) {
                this.resetIsEndStatus()
                this.applyFiltersAndSorting(Number(this.categoryId));
            }
        },
    },
}
</script>

<style scoped lang="scss">
    .underlinedPositions {
        text-decoration: underline;
        text-underline-offset: 4px;
    }

    .filter-control {
        &__wrapper {
            display: flex;
            max-width: 100%;
            overflow: hidden;
        }

        &__categories {
            width: calc(100% - 90px);
            overflow-x: scroll;
            margin-right: 10px;
            padding-bottom: 1.25rem;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0px;
                right: -10px;
                width: 60px;
                height: 100%;
                background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
                z-index: 10;
            }
        }
        &__dialog-control {
            padding: 1.25rem 25px;
        }
    }

    @media screen and (max-width: 880px) { 
        .filter-control {
            &__categories {
                width: 100%;
            }

            &__dialog-control {
                position: absolute;
                right: 0px;
                z-index: 10;
            }
        }
    }
</style>
