<template>
    <div class="app-landing__wrapper">
        <div class="app-landing__inner">
            <div class="app-landing__left w-full lg:w-1/2">
                <img src="/landing/images/app_landing_bg.png" />
            </div>
            <div class="app-landing__right w-full lg:w-1/2">
                <div class="app-landing__qr-wrapper">
                    <div>
                        <div class="app-landing__qr">
                            <img src="/landing/images/landing_app_qr.svg"/>
                        </div>
                        <div class="app-landing__qr-desc">
                            <div>
                                <a href="https://apps.apple.com/ru/app/present-simple-%D0%BC%D0%B0%D0%B3%D0%B0%D0%B7%D0%B8%D0%BD-%D0%BE%D0%B4%D0%B5%D0%B6%D0%B4%D1%8B/id6736971713" target="_blank">Приложение Present & Simple</a>
                            </div>
                            <div class="app-landing__links">
                                <a class="app-landing__mobile__download" href="https://apps.apple.com/ru/app/present-simple-%D0%BC%D0%B0%D0%B3%D0%B0%D0%B7%D0%B8%D0%BD-%D0%BE%D0%B4%D0%B5%D0%B6%D0%B4%D1%8B/id6736971713" target="_blank">
                                    <img src="/landing/images/apple_logo.svg" />
                                    <span>App Store</span>
                                </a>
                                <a class="app-landing__mobile__download" href="https://play.google.com/store/apps/details?id=ru.presentsimple.android&pli=1" target="_blank">
                                    <img src="/landing/images/google_pay_logo.svg" />
                                    <span>Google Play</span>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'AppLanding',
    data() {
        return {

        }
    }

}
</script>

<style lang="scss" scoped>
    .app-landing {
        &__inner {
            display: flex;
        }
        &__right {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
        &__qr-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
        &__qr {
            display: flex;
            justify-content: center;
        }
        &__qr-desc {
            text-align: center;
            margin-top: 30px;
        }
        &__mobile__download {
            display: none;
        }
    }

    @media screen and (max-width: 600px) {
        .app-landing {
            &__inner {
                flex-direction: column-reverse;
            }

            &__right {
                padding: 40px 0px;
            }
            &__qr {
                display: none;
            }
            &__qr-desc {
                margin-top: 0px;
            }
            &__mobile__download {
                margin-top: 30px;
                display: flex;
                justify-content: center;
                width: 170px;
                height: 38px;
                line-height: 38px;
                border: 1px solid #000;

                img {
                    width: 12.5px;
                    margin-right: 12px;
                }
            }
            &__links {
                width: 350px;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    @media screen and (max-width: 380px) {
        .app-landing {
            &__mobile__download {
                width: 100%;
            }
        }
    }
</style>