<template>
    <AnnouncementBar v-if="!(isMobile && mobileHumburgerStatus)" ref="announcementBar"/>
    <div 
        :class="[
            `header${isMobile ? '-mobile' : ''}__wrapper`, 
            {'header-scrolled': !headerInTop}, 
            {'header-bg-white': isBackgroundWhite},
            {'header-not-main-page': !isMainPage},
            {'h-[100vh]': isMobile && mobileHumburgerStatus}   
        ]"   
        @mouseleave="() => { hideSubMenu(); mouseOnHeader = false  }"
        @mouseover="() => { mouseOnHeader = true }"
        :style="{top: `${isMobile && mobileHumburgerStatus ? 0 : announcementBarHeight}px`}"
    >
        <div v-if="isMobile">
            <div>
                <div class="header-mobile__inner">
                    <div class="header-mobile__top">
                        <div class="header-mobile__left w-[50px]">
                            <div class="header-mobile___img-link">
                                <img @click="toggleHumburgerStatus" v-if="mobileHumburgerStatus" :src="`/resources/images/header/close${ !isBackgroundWhite ? `_white` : ``}.svg`" />
                                <img @click="toggleHumburgerStatus" v-else :src="`/resources/images/header/humburger${ !isBackgroundWhite ? `_white` : ``}.svg`" />
                            </div>
                        </div>
                        <div class="header-mobile__center">
                            <router-link :to="`/`">
                                <img class="header-mobile__logo" :src="`/resources/images/header/logo${ !isBackgroundWhite ? `_white` : ``}.svg`" alt="present simple logo"/>
                            </router-link>
                        </div>
                        <div class="header-mobile__right w-[50px]">
                            <div class="header-mobile___img-link">
                                <router-link :to="`/search`">
                                    <img :src="`/resources/images/header/search${ !isBackgroundWhite ? `_white` : ``}.svg`" />
                                </router-link>
                            </div>
                            <div class="header-mobile___img-link">
                                <router-link :to="`/cart`">
                                    <img :src="`/resources/images/header/card_new${!isBackgroundWhite ? `_white` : ``}.svg`" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="header-mobile__bottom" v-if="mobileHumburgerStatus">
                        <ul class="header-mobile__menu">
                            <li :class="[{'selected': selectedMenuIndex === 1}]" @click="() => showSubMenu(1)">Каталог</li>
                            <li :class="[{'selected': selectedMenuIndex === 2}]" @click="() => showSubMenu(2)">О нас</li>
                            <li :class="[{'selected': selectedMenuIndex === 3}]" @click="() => showSubMenu(3)">Магазины</li>
                            <li><router-link :to="`/account`">Аккаунт</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="header-mobile__sub-menu__wrapper" ref="headerSubmenu" v-if="subMenuIsVisible && mobileHumburgerStatus" :style="{height: `${mobileSubmenuHeight}px`}">
                    <div class="header-mobile__sub-menu__inner" v-if="selectedMenuIndex === 1">
                        <HeaderRowMobile 
                            v-for="catalogColum, columIndex in catalogItems" 
                            :catalogColum="catalogColum" 
                            :columIndex="columIndex"
                            :catalogItemsLength="catalogItems.length"
                        />
                        <div class="mt-[50px]">
                            <div>
                                <router-link :to="`/catalog/novinki`">
                                    <img src="/resources/images/header/test_bg.png"/>
                                    <p class="mt-[10px]">Новинки обуви</p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="header-mobile__sub-menu__inner" v-if="selectedMenuIndex === 2">
                        <HeaderRowMobile 
                            v-for="catalogColum, columIndex in aboutUsItems" 
                            :catalogColum="catalogColum" 
                            :columIndex="columIndex"
                            :catalogItemsLength="aboutUsItems.length + 1"
                        />
                        <hr />
                        <div class="header__sub-menu__subscribe-form">
                            <p class="text-14 mb-[15px]">Узнавайте первыми о специальных предложениях<br/> и секретных распродажах</p>
                            <SubscribeForm></SubscribeForm>
                        </div>
                        <div class="pt-[10px]">
                            <router-link :to="`/gift-certificate`">
                                <img src="/resources/images/header/test_bg_2.png"/>
                                <p class="mt-[10px]">Подарочная карта</p>
                            </router-link>
                        </div>
                    </div>
                    <div class="header-mobile__sub-menu__inner" v-if="selectedMenuIndex === 3">
                        <div>
                            <div class="mb-[30px]">
                                <router-link @click="clearMenuState" :to="`/info/stores`">
                                    <img class="header-mobile__address-bg" :src="`/resources/images/header/address_bg_1.png`" />
                                    <p>Адреса магазинов</p>
                                </router-link>
                            </div>
                            <div>
                                <router-link @click="clearMenuState" :to="`/info/stores`">
                                    <img class="header-mobile__address-bg" :src="`/resources/images/header/address_bg_2.png`" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="header__inner">
                <div class="header__inner-left">
                    <nav class="header__menu-wrapper">
                        <ul class="header__menu">
                            <li :class="[{'selected': selectedMenuIndex === 1}]" @mouseover="() => showSubMenu(1)">Каталог</li>
                            <li :class="[{'selected': selectedMenuIndex === 2}]" @mouseover="() => showSubMenu(2)">О нас</li>
                            <li :class="[{'selected': selectedMenuIndex === 3}]" @mouseover="() => showSubMenu(3)">Магазины </li>
                        </ul>
                    </nav>
                </div>
                <div class="header__inner-center">
                    <div  class="header__logo-wrapper">
                        <router-link :to="`/`">
                            <img class="header__logo" :src="`/resources/images/header/logo${!isBackgroundWhite ? `_white` : ``}.svg`" alt="present simple logo"/>
                        </router-link>
                    </div>
                </div>
                <div class="header__inner-right">
                    <nav class="header__menu-wrapper">
                        <ul class="header__menu">
                            <li><router-link :to="`/search`">Поиск</router-link></li>
                            <li><router-link :to="`/account`">Аккаунт</router-link></li>
                            <li class="header__img-link">
                                <router-link :to="`/cart`">
                                    <img :src="`/resources/images/header/card_new${!isBackgroundWhite ? `_white` : ``}.svg`" />
                                </router-link>
                                <span v-if="quantityOfProductsInAllCarts > 0" class="header__card-counter">{{ quantityOfProductsInAllCarts }}</span>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="header__sub-menu__wrapper" v-if="subMenuIsVisible">
                <div class="header__sub-menu__inner" v-if="selectedMenuIndex === 1">
                    <div class="header__sub-menu__left">
                        <HeaderColumn v-for="catalogColum in catalogItems" :catalogColum="catalogColum"  />
                    </div>
                    <div class="header__sub-menu__right">
                        <div class="pl-[30px]">
                            <router-link :to="`/catalog/novinki`">
                                <img src="/resources/images/header/test_bg.png"/>
                                <p class="mt-[10px]">Новинки обуви</p>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="header__sub-menu__inner" v-else-if="selectedMenuIndex === 2">
                    <div class="header__sub-menu__left">
                        <HeaderColumn v-for="aboutUsColum in aboutUsItems" :catalogColum="aboutUsColum"  />
                        <div class="header__sub-menu__column">
                            <div class="header__sub-menu__subscribe-form">
                                <p class="text-14 mb-[15px]">Узнавайте первыми о специальных предложениях<br/> и секретных распродажах</p>
                                <SubscribeForm></SubscribeForm>
                            </div>
                        </div>
                    </div>
                    <div class="header__sub-menu__right">
                        <div class="pl-[30px]">
                            <router-link :to="`/gift-certificate`">
                                <img src="/resources/images/header/test_bg_2.png"/>
                                <p class="mt-[10px]">Подарочная карта</p>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="header__sub-menu__inner" v-else-if="selectedMenuIndex === 3">
                    <div class="flex">
                        <div class="mr-[30px]">
                            <router-link @click="clearMenuState" :to="`/info/stores`">
                                <img class="header__address-bg mb-[10px]" :src="`/resources/images/header/address_bg_1.png`" />
                                <p>Адреса магазинов</p>
                            </router-link>
                        </div>
                        <div>
                            <router-link @click="clearMenuState" :to="`/info/stores`">
                                <img class="header__address-bg mb-[10px]" :src="`/resources/images/header/address_bg_2.png`" />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header-bg" v-if="subMenuIsVisible"></div>
</template>

<script>
import HeaderRowMobile from './HeaderRowMobile.vue'
import AnnouncementBar from '../../../components/AnnouncementBar.vue'
import HeaderColumn from './HeaderColumn.vue'
import SubscribeForm from '../SubscribeForm.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: "Header",
    components: { SubscribeForm, HeaderRowMobile, HeaderColumn, AnnouncementBar },
    data() {
        return {
            headerInTop: true,
            mouseOnHeader: false,
            subMenuIsVisible: false,
            mobileHumburgerStatus: false,
            emailFieldIsSelected: false,

            windowWidth: window.innerWidth,
            selectedMenuIndex: null,
            announcementBarHeight: 44,
        }
    },
    computed: {
        ...mapGetters({
            cart: 'cart',
            preorderCart: 'preorderCart',
            navigation: 'navigation',
            navigationStatus: 'navigationStatus',
            announcementBarStatus: 'announcementBarStatus'
        }),
        isMobile() {
            return this.windowWidth < 750
        },
        isMainPage() {
            return this.$route.fullPath === '/'
        },
        isBackgroundWhite() {
            return (this.selectedMenuIndex && this.mouseOnHeader) || !this.headerInTop || !this.isMainPage || (this.isMobile && this.mobileHumburgerStatus)
        },
        catalogItems() {
            if(this.navigation) {
                const mapResult = [];
                let mapCursor = 0

                const items = this.navigation.data.find(nav => nav.data.navigation_category_id === 1).data.attributes.navigation_items.data;

                items.forEach((i) => {
                    if(i.data.attributes.navigation_sub_items.data.length > 0) mapCursor++
                    if(i.data.attributes.title === 'Подарочная карта') mapCursor++

                    mapResult[mapCursor] ? mapResult[mapCursor].push(i) : mapResult[mapCursor] = [i]
                });
                return mapResult
            } else {
                return  []
            }
        },
        aboutUsItems() {
            if(this.navigation) {
                const mapResult = [];
                let mapCursor = 0
                const items = this.navigation.data.find(nav => nav.data.navigation_category_id === 8).data.attributes.navigation_items.data;

                items.forEach((i) => {
                    if(i.data.attributes.navigation_sub_items.data.length > 0) mapCursor++

                    mapResult[mapCursor] ? mapResult[mapCursor].push(i) : mapResult[mapCursor] = [i]
                });
                return mapResult
            } else {
                return  []
            }
        },
        quantityOfProductsInAllCarts() {
            return this.cart && this.preorderCart ? this.cart.data.attributes.itemsQuantity + this.preorderCart.data.attributes.itemsQuantity : 0 
        },
        mobileSubmenuHeight() {
            return window.innerHeight - 120
        }
    },
    methods: {
        showSubMenu(menuIndex) {
            if(this.isMobile) this.$refs.headerSubmenu?.scrollTo({ top: 0 })
            
            this.selectedMenuIndex = menuIndex
            this.subMenuIsVisible = true
        },
        hideSubMenu() {
            this.subMenuIsVisible = false
        },
        selectCategory(categoryId) {
            this.selectedcategoryId = categoryId
        },
        clearMenuState() {
            this.subMenuIsVisible = this.mobileHumburgerStatus = false
            this.selectedMenuIndex = null
        },
        toggleHumburgerStatus() {
            this.mobileHumburgerStatus = !this.mobileHumburgerStatus
            this.mobileHumburgerStatus ? this.selectedMenuIndex = 1 : this.selectedMenuIndex = null
        },
        routeInclude(includeString) {
            return this.$route.fullPath.includes(includeString)
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            this.headerInTop = window.scrollY < this.announcementBarHeight
        });
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
        })

        this.$store.dispatch('fetchNavigation')
        document.addEventListener('click', e => { if(e.target.tagName === 'A') this.clearMenuState() })
    },
    watch: {
        mobileHumburgerStatus() {
            this.subMenuIsVisible = this.mobileHumburgerStatus
        },
        mouseOnHeader(newVal) {
            if(!newVal) this.selectedMenuIndex = null
        },
        announcementBarStatus(status) {
            setTimeout(() => { if(status === 'success') this.announcementBarHeight = this.$refs.announcementBar?.$el?.clientHeight || 41 }, 100)
        }
    }
}
</script>

<style lang="scss" scoped>
    .header {
        &__wrapper {
            width: 100%;            
            position: absolute;
            left: 0px;
            z-index: 1000;

            &.header-not-main-page {
                position: static;
            }
            
            &.header-scrolled {
                position: fixed;
                top: 0px !important;
                left: 0px;
                z-index: 1000;
            }

            &.header-bg-white {
                background-color: #fff;

                .header__menu li {
                    color: #000;
                }
            }

            &:not(.header-bg-white) {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
            }

            a {
                font-size: 14px;
                line-height: 17.95px;
            }
        }

        &__address-bg {
            height: 386px;
            //height: 280px;
        }

        &__inner {
            display: flex;
            justify-content: space-between;
            padding-left: 35px;
            padding-right: 35px;
        }
        &__inner > div {
            width: 33.333333%;
        }
        &__menu-wrapper {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            background: transparent;
        }
        &__inner > div {
            display: flex;
        }
        &__inner-center {
            justify-content: center;
        }
        &__inner-left {
            justify-content: flex-start;
        }
        &__inner-right {
            justify-content: flex-end;
        }
        &__menu {
            display: flex;
            list-style: none;
        }
        &__menu li {
            cursor: pointer;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            line-height: 17.95px;
            border-bottom: 1px solid transparent;

            &:not(.header__img-link){
                &:hover, &.include, &.selected  {
                    border-bottom: 1px solid;
                }
            }

            &.header__img-link {
                position: relative;

                .header__card-counter {
                    position: absolute;
                    font-size: 9px;
                    font-weight: 400;
                    right: -7px;
                    top: -10px;
                }
            }
        }
        &__menu li:not(:last-child) {
            margin-right: 30px;
        }
        &__logo {
            width: 225px;
            margin-top: 18px;
            margin-bottom: 26px;
        }
        &__sub-menu__left {
            display: flex;
            justify-content: flex-start;
            //justify-content: space-between;
            padding-right: 30px;
            flex-grow: 1;
        }
        &__sub-menu__right {
            img {
                min-width: 289px;
                height: 386px;
            }
        }
        &__sub-menu {
            &__wrapper {
                background-color: #fff;
                min-height: 480px;
            }

            &__inner {
                display: flex;
                padding: 30px 35px;
                justify-content: space-between;
                align-items: stretch;
            }

            &__subscribe-form {
                display: flex;         
                flex-direction: column;
                width: 350px;
                height: 100%;

                @media screen and (max-width: 600px) {
                    width: 100%;
                }

                input[type=email] {
                    width: 100%;
                    height: 38px;
                    margin-top: 15px;
                    border: 1px solid #d9d9d9;
                }

                form {
                    width: 100%;
                    position: relative;

                    button {
                        width: 13px;
                        position: absolute;
                        bottom: 14px;
                        right: 10px;
                    }
                }

                &__desc {
                    font-size: 11px;
                    color: #8E8E8E;
                    margin-top: 10px;
                }
            }
        }
    }

    .header-mobile {
        &__wrapper {
            width: 100%;            
            position: absolute;
            left: 0px;
            z-index: 1000;
            transition: all 0.1s linear;

            &.header-not-main-page {
                position: static;
            }

            &.header-scrolled {
                position: fixed;
                top: 0px !important;
                left: 0px;
                z-index: 1000;
            }

            &.header-bg-white {
                background-color: #fff;

                .header__menu li {
                    color: #000;
                }
            }

            &:not(.header-bg-white) {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }
        &__inner {
            padding-left: 20px;
            padding-right: 20px;
        }
        &__top {
            display: flex;
            justify-content: space-between
        }
        &__logo {
            margin: 18px auto;
            width: 168px;
        }
        &__left {
            display: flex;
        }
        &__right {
            display: flex;
            justify-content: end;
        }
        &__menu {
            display: flex;
            li {
                list-style: none;
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: 30px;
                }

                &.selected {
                    border-bottom: 1px solid;
                }
            }
        }

        &__sub-menu {
            &__wrapper {
                overflow-y: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;
                background: #fff;

                &::-webkit-scrollbar {
                    display: none;
                }

                hr {
                    margin: 30px -20px;
                }
            }

            &__inner {
                padding: 20px;
            }
        }

        &__bottom {
            margin-right: -20px;
            margin-left: -20px;
            padding: 20px;
            background: #fff;
        }

        &___img-link {
            display: flex;
            cursor: pointer;

            a {
                display: flex;
            }
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    .header-bg {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 15%);
        z-index: 9;
    }
</style>