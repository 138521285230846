<template>
    <div 
        :class="[
            'header-mobile__sub-menu__row', 
            {'with-accordion': catalogItemsLength === 1}, 
            {'last': columIndex === catalogItemsLength - 1},
            {'pre-last': columIndex === catalogItemsLength - 2},
        ]" 
    >
        <ul 
            v-for="catalogItem, catalogIndex in catalogColum" 
            :class="[`header-mobile__sub-menu`, { 'header-mobile__sub-menu__accordion': catalogItem.data.attributes.navigation_sub_items.data.length }]" 
            :ref="`accordion_${columIndex}_${catalogIndex}`"
        >
            <li :class="[`header-mobile__sub-menu__title`, { 'space-bottom': catalogItem.data.attributes.marginBottom }]">
                <span 
                    v-if="catalogItem.data.attributes.navigation_sub_items.data.length" 
                    @click="() => { toggleAccordion(`accordion_${columIndex}_${catalogIndex}`)}" 
                >
                    {{ catalogItem.data.attributes.title}}
                    <sup>{{ catalogItem.data.attributes.tag }}</sup>
                </span>
                <router-link v-else :to="catalogItem.data.attributes.path">
                    {{ catalogItem.data.attributes.title}}
                    <sup>{{ catalogItem.data.attributes.tag }}</sup>
                </router-link>

                <img 
                    v-if="catalogItem.data.attributes.navigation_sub_items.data.length"
                    class="header-mobile__sub-menu__accordion__btn"  
                    @click="() => { toggleAccordion(`accordion_${columIndex}_${catalogIndex}`)}" 
                    src="/resources/images/header/accordion-btn-down.svg" 
                />
            </li>
            <li v-for="catalogSubItem in catalogItem.data.attributes.navigation_sub_items.data">
                <router-link :to="catalogSubItem.data.attributes.path">{{ catalogSubItem.data.attributes.title }}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'HeaderRowMobile',
    props: {
        columIndex: null,
        catalogColum: null,
        catalogItemsLength: null
    },
    data() {
        return {

        }
    },
    methods: {
        toggleAccordion(refName) {
            let refClassList = this.$refs[refName][0].classList;
            let className = 'header-mobile__sub-menu__open';

            refClassList.contains(className) ? refClassList.remove(className) : refClassList.add(className)
        }
    }
}
</script>

<style lang="scss" scoped>
.header-mobile__sub-menu {
    list-style: none;

    li {
        margin-bottom: 10px;

        &.space-bottom {
            margin-bottom: 30px;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between
    }

    &__row {
        /*&:not(.with-accordion) {
            margin-bottom: 30px;
        }*/

        &.last {
            padding-top: 30px;
            margin-top: 30px;
            border-top: 1px solid #d9d9d9;

            margin-left: -20px;
            margin-right: -20px;

            padding-left: 20px;
            padding-right: 20px;
        }

        &.pre-last {
            .header-mobile__sub-menu__open {
                padding-bottom: 0px;
            }
        }
    }  

    &__accordion {
        &:not(.header-mobile__sub-menu__open) {
            li:not(:first-child) {
                display: none;
            }
        }

        &.header-mobile__sub-menu__open {
            padding-bottom: 30px;
        }
        
        &__btn {
            cursor: pointer;
        }
    }

    &__open {
        li {
            :first-child {
                color: #8e8e8e;
            }

            &:not(:first-child) {
                padding-left: 10px;

                a {
                    color: #000;
                }
            }

            .header-mobile__sub-menu__accordion__btn {
                transform: rotateZ(180deg);
            }
        }
    }  
}
</style>