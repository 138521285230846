<template>
    <div>
        <div class="w-full">
            <div class="" v-for="(item, index) in cart.data.relationships.orderLines.data"
                 :key="'cart_item_'+item.relationships.product.data.product_id+'size'+item.relationships.size_id" >
                <div v-if="item.attributes.isProductActive === true && item.attributes.maxQuantity > 0">
                    <div v-if="item.relationships.product.data.attributes.isPreorder === true" class="pt-4 text-11 text-brown-dark text-left lg:text-13">
                        Вы оформляете предзаказ на эту модель. Ориентировочная дата отправки
                        — с {{  new Date(item.relationships.product.data.attributes.preorderDeliveryDateFrom).toLocaleDateString('ru').slice(0,5) }}
                        по {{ new Date(item.relationships.product.data.attributes.preorderDeliveryDateTo ).toLocaleDateString('ru').slice(0,5)}} включительно
                    </div>
                    <div :class="[index === (cart.data.relationships.orderLines.data.length - 1) ? 'flex py-4 justify-between' : 'flex py-4 justify-between border-b border-gray-lighter']">
                        <div class="flex">
                            <div class="w-21 h-full flex-none overflow-hidden lg:w-26">
                                <img class="w-full object-cover" :src="item.relationships.product.data.attributes.images.data[0].data.attributes.path"
                                     :alt="item.relationships.product.data.attributes.title">
                            </div>
                            <div class="">
                                <div class="ml-4 uppercase text-11 text-black font-normal lg:w-95 lg:ml-5 lg:text-16">
                                    {{ item.relationships.product.data.attributes.title }}
                                </div>
                                <div class="ml-4 items-center text-11 text-gray-light font-normal lg:ml-5 lg:text-16 ">
                                    <div>
                                        Артикул: {{ item.relationships.product.data.attributes.article_number }}
                                    </div>
                                    <div class="flex mt-0.5 items-center">
                                        <div
                                            class="w-3.5 h-3.5 mr-1.5 border border-black rounded rounded-full flex items-center justify-center">
                                            <div
                                                v-if="item.relationships.product.data.attributes.color.data.attributes.code"
                                                class="w-2.5 h-2.5 rounded rounded-full border-border-gray-light"
                                                :style="'background-color: ' + item.relationships.product.data.attributes.color.data.attributes.code + ';'">
                                            </div>
                                        </div>
                                        <div class="">
                                            {{ item.relationships.product.data.attributes.color.data.attributes.title }}
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-4 pt-4 uppercase text-11 text-black font-normal lg:text-16 lg:ml-5">
                                    <div v-for="size in item.relationships.product.data.attributes.sizes.data"
                                         :key="size.id"
                                         class="bg-white">
                                        <div v-if="size.data.size_id === item.attributes.sizeId">
                                            {{ size.data.attributes.title }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid justify-items-end">
                            <div></div>
                            <div class="w-30 grid justify-items-end content-end lg:w-45">
                                <div class="mr-2 flex text-11 text-gray-light text-right lg:text-16">
                                    {{ item.attributes.userQuantity }} шт.
                                </div>
                                <div class="">
                                    <div v-if="item.attributes.totalDiscount === 0"
                                         class="mr-2 flex text-11 text-black text-right lg:text-16">
                                        {{ renderPriceFormat(item.attributes.totalPrice) }} ₽
                                    </div>
                                    <div v-else class="flex pr-2 text-11 text-black text-right lg:text-16">
                                        <div>{{ renderPriceFormat(item.attributes.totalPrice) }} ₽</div>
                                        <span
                                            class="ml-3 text-gray-light text-right text-11 font-normal line-through lg:text-16">{{
                                                renderPriceFormat(item.attributes.basicPrice)
                                            }} ₽</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CartCheckoutOrderLines",
    props: {
        cart: null
    }
}
</script>

<style scoped>

</style>
