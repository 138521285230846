<template>
    <div class="header__sub-menu__column">
        <ul class="header__sub-menu" v-for="catalogItem in catalogColum">
            <li :class="[
                {'header__sub-menu__title': catalogItem.data.attributes.navigation_sub_items.data.length},
                {'space-bottom': catalogItem.data.attributes.marginBottom}
            ]">
                <router-link class="relative" :to="catalogItem.data.attributes.path">
                    {{ catalogItem.data.attributes.title}}
                    <sup class="header__tag">{{ catalogItem.data.attributes.tag }}</sup>
                </router-link>
            </li>
            <li v-for="catalogSubItem in catalogItem.data.attributes.navigation_sub_items.data">
                <router-link :to="catalogSubItem.data.attributes.path">{{ catalogSubItem.data.attributes.title }}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'HeaderColumn',
    props: {
        catalogColum: null,
    }
}
</script>

<style lang="scss" scoped>
    .header__sub-menu {
        list-style: none;

        li {
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            
            &:not(.header__sub-menu__title) {
                a:hover {
                    border-bottom: 1px solid;
                } 
            }
            
            &.space-bottom {
                margin-bottom: 30px;
            }

            &.header__sub-menu__title {
                color: #8E8E8E;
                margin-bottom: 20px;
            }
        }

        &__column {
            min-width: 190px;
            padding-right: 10px;
        }
    }

    .header__tag {
        position: absolute;
        left: calc(100% + 5px);
        top: 2px;
    }
</style>