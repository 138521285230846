<template>
    <div class="w-full px-5 mx-auto overflow-hidden lg:w-240 xl:w-360">
        <CartMain :type="'preorderCart'"
                  :preorder-cart="preorderCart"
                  :cart="cart"/>

        <div v-if="preorderCart?.data.relationships.orderLines?.data.length > 0">
            <PreorderCartOrderLines :cart="preorderCart"/>

            <div class="flex-none mt-10 lg:flex">
                <div class="w-full lg:w-1/2">
                    <div>
                        <PreorderCartUserDeliveryPaymentInfo :cart="preorderCart" />
                    </div>
                </div>
                <div class="w-full mt-10 lg:w-1/2 lg:mt-0 lg:ml-15">
                    <PreorderCartInfoPopup />
                    <CartDeliveryAndReturnInfo />
                    <div class="mt-10 mb-7 text-gray-main text-base font-normal">
                        <div  class="flex items-center justify-between uppercase text-11 text-gray-light lg:text-13">
                            <div>Доставка:</div>
                            <div class="pl-3">
                                {{renderPriceFormat(preorderCart?.data.attributes.deliveryPriceToPay)}} ₽
                            </div>
                        </div>
                        <div class="mt-3 flex items-center justify-between uppercase text-13 text-black lg:text-16">
                            <div>Итого:</div>
                            <div class="pl-3" >
                                <div v-if="preorderCart?.data.attributes.priceToPay !== preorderCart?.data.attributes.totalBasicPrice">
                                    {{renderPriceFormat(preorderCart?.data.attributes.priceToPay)}} ₽
                                    <span class="pl-1 text-gray-light font-normal line-through">
                        {{renderPriceFormat(preorderCart?.data.attributes.totalBasicPrice)}} ₽
                    </span>
                                </div>
                                <div v-else>
                                    {{renderPriceFormat(preorderCart?.data.attributes.priceToPay)}} ₽
                                </div>
                            </div>
                        </div>
                    </div>
                    <button @click="createPreorderFromCart(preorderCart)"
                            :class="[this.preorderCart?.data.attributes.isPossibleToSend
                  ? 'w-full uppercase h-9 py-1 px-5 bg-black text-white text-11 r focus:outline-none lg:text-13'
                  : 'w-full uppercase h-9 py-1 px-5 bg-gray-light text-white text-11 r focus:outline-none lg:text-13']">
                        Оформить предзаказ
                    </button>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="flex items-center justify-center text-11 my-10 lg:text-24">Ваша корзина пуста</div>
        </div>
        <div data-popmechanic-embed="40986"></div>

    </div>
</template>

<script>
import CartAdditionalOrderLines from "../../components/Cart 2.0/CartAdditionalOrderLines.vue";
import CartOrderLines from "../../components/Cart 2.0/CartOrderLines.vue";
import CartDeliveryAndReturnInfo from "../../components/Cart 2.0/CartDeliveryAndReturnInfo.vue";
import CartDeliveryAndReturnPopups from "../../components/Cart 2.0/CartDeliveryAndReturnInfo.vue";
import CartTotalCalculations from "../../components/Cart 2.0/CartTotalCalculations.vue";
import CartIncrementDecrementQuantityOfOrderLine
    from "../../components/Cart 2.0/CartIncrementDecrementQuantityOfOrderLine.vue";
import CartGiftCertificateBlock from "../../components/Cart 2.0/CartGiftCertificateBlock.vue";
import CartPromoCodeBlock from "../../components/Cart 2.0/CartPromoCodeBlock.vue";
import CartSizeSelect from "../../components/Cart 2.0/CartSizeSelect.vue";
import Login from "../../components/Auth/Login.vue";
import {mapActions, mapGetters} from "vuex";
import PreorderCartOrderLines from "../../components/preorderCart/PreorderCartOrderLines.vue";
import PreorderCartUserDeliveryPaymentInfo from "../../components/preorderCart/PreorderCartUserDeliveryPaymentInfo.vue";
import CartUserBlock from "../../components/Cart 2.0/CartUserBlock.vue";
import CartCheckoutUserDeliveryPaymentInfo from "../../components/Cart 2.0/CartCheckoutUserDeliveryPaymentInfo.vue";
import CartMain from "./CartMain.vue";
import PreorderCartInfoPopup from "../../components/preorderCart/PreorderCartInfoPopup.vue";

export default {
    name: "PreorderCart",
    components: {
        PreorderCartInfoPopup,
        CartMain,
        CartCheckoutUserDeliveryPaymentInfo,
        CartUserBlock,
        PreorderCartUserDeliveryPaymentInfo,
        PreorderCartOrderLines,
        CartDeliveryAndReturnInfo,
        CartDeliveryAndReturnPopups,
        CartAdditionalOrderLines,
        CartOrderLines,
        CartTotalCalculations,
        CartIncrementDecrementQuantityOfOrderLine,
        CartGiftCertificateBlock,
        CartPromoCodeBlock,
        CartSizeSelect,
        Login
    },
    data: () =>({
        isCreateOrderButtonActive:false,
        loginInCart: false,
        isCustomerSureWantsToDeleteOrderLinePopupVisible: false,
    }),
    methods:{
        ...mapActions({
            getCartVuex:'getCart',
            addOrderLineToCartVuex:'addOrderLineToCart',
            deleteOrderLineFromCartVuex:'deleteOrderLineFromCart',
            changeOrderLineSizeInCartVuex:'changeOrderLineSizeInCart',
            createPreorder:'createPreorder',
        }),
        async createPreorderFromCart(preorderCart){
            if (preorderCart?.data.attributes.isPossibleToSend){

                await this.createPreorder()
                    .then( () => {
                        this.$gtm.trackEvent({
                            event: "checkout",
                            cartAmount: this.preorderCart?.data.attributes.priceToPay,
                            noninteraction: false,
                        });
                      //Расширенная электронная торговля
                      this.$gtm.trackEvent({
                        event: 'flushVariables',
                        ecommerce: null,
                      });
                      let products = []

                      for (let item of this.preorder.data.attributes.lines.data) {
                        products.push({
                          id: item.data.attributes.product.data.attributes.article_number,
                          name: item.data.attributes.product.data.attributes.title,
                          price: item.data.attributes.amount,
                          category: item.data.attributes.product.data.attributes.category_title,
                          quantity: item.data.attributes.quantity,
                          variant: item.data.attributes.product.data.attributes.article_number + '' + item.data.attributes.size.data.size_id,

                        })
                      }

                      this.$gtm.trackEvent({
                        event: 'purchase', // Event type [default = 'interaction'] (Optional)
                        pageType: 'purchase',
                        ecommerce: {
                          purchase: {
                            actionField: {
                              id: this.preorder.data.order_id,
                              affiliation: 'Present&Simple',
                              revenue: this.preorder.data.attributes.amount,
                              shipping: this.preorder.data.attributes.delivery.data.attributes.amount,
                              coupon: null
                            },
                            products: products,
                          }
                        },
                      });
                      if (this.preorder?.data?.attributes?.invoice_url !== null) {
                        this.$router.push('/order-created-pay-next');
                      } else {
                        this.$router.push('/payment/on_demand');
                      }
                    })
                    .catch(error => {
                        this.isErrorInCreatingOrder = true
                    })
            }
        },
    },
    computed: {
        ...mapGetters ({
            preorderCart: 'preorderCart',
            cart: 'cart',
            preorder: 'preorder',
            authInfo: 'authInfo',
        }),
    },
    mounted () {
        this.$store.dispatch('getAuthStatus')
        this.$store.dispatch('getPreorderCart')
        this.$store.dispatch('getCart')
    },
}
</script>

<style scoped>

</style>


