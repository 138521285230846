<template>
    <PopupOnRightSide v-if="isFiltersActive" v-click-outside="closePopup"
                      @closePopup="closePopup" class="">
        <div class="relative">
            <div class="flex justify-between">
                <div class="mt-10 uppercase text-14 text-gray-main-2">
                    Фильтры
                </div>
                <button v-if="isAnyFilterActive"
                        @click="resetAllFilters()"
                        class="mt-10 text-14 text-gray-main-2">
                    сбросить все
                </button>
            </div>
            <accordion>
                <accordion-item>
                    <template class="flex " v-slot:accordion-trigger="slotProps">
                        <div class="mt-5 flex items-center justify-between cursor-pointer">
                            <div class="w-full my-2 flex items-center uppercase text-14 text-black">
                                Сортировка
                            </div>
                            <div class="flex items-center">
                                <div v-if="isSortNotDefault">
                                    <button @click="resetSortBy()"
                                            class="mr-7 text-14 text-gray-main-2">
                                        сбросить
                                    </button>
                                </div>
                                <svg width="11" height="7" viewBox="0 0 11 7" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                     :class="[slotProps.visible ? 'transform rotate-180' : '']">
                                    <path d="M5.5 5.5L10 1" stroke="black" stroke-linecap="square"/>
                                    <path d="M1 1.00037L5.5 5.50037" stroke="black" stroke-linecap="square"/>
                                </svg>
                            </div>
                        </div>
                    </template>
                    <template v-slot:accordionContent>
                        <div>
                            <div @click="sort('default')"
                                :class="sortBy === 'default' ? 'underlinedPositions' : ''"
                                class="pl-[10px] pr-5 mb-[2px] text-left h-8 bg-white flex items-center justify-start text-14 text-gray-main  cursor-pointer border border-white hover:border-black">
                                Наш выбор
                            </div>
                            <div @click="sort('price')"
                                :class="sortBy === 'price' ? 'underlinedPositions' : ''"
                                class="pl-[10px] pr-5 mb-[2px] text-left h-8 bg-white flex items-center justify-start text-14 text-gray-main cursor-pointer border border-white hover:border-black">
                                По возрастанию цены
                            </div>
                            <div @click="sort('-price')"
                                :class="sortBy === '-price' ? 'underlinedPositions' : ''"
                                class="pl-[10px] pr-5 mb-[2px] text-left h-8 bg-white flex items-center justify-start text-14 text-gray-main cursor-pointer border border-white hover:border-black">
                                По убыванию цены
                            </div>
                        </div>
                    </template>
                </accordion-item>

                <accordion-item>
                    <template class="flex " v-slot:accordion-trigger="slotProps">
                        <div class="flex items-center justify-between cursor-pointer">
                            <div class="w-full my-2 flex items-center uppercase text-14 text-black">
                                Цвет
                                <span class="ml-1" v-if="isColorFilterActive">
                                         ({{ isColorFilterActive }})
                                    </span>
                            </div>
                            <div class="flex items-center">
                                <div v-if="isColorFilterActive">
                                    <button @click="resetColorFilter()"
                                            class="mr-7 text-14 text-gray-main-2">
                                        сбросить
                                    </button>
                                </div>
                                <svg width="11" height="7" viewBox="0 0 11 7" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                     :class="[slotProps.visible ? 'transform rotate-180' : '']">
                                    <path d="M5.5 5.5L10 1" stroke="black" stroke-linecap="square"/>
                                    <path d="M1 1.00037L5.5 5.50037" stroke="black" stroke-linecap="square"/>
                                </svg>
                            </div>
                        </div>
                    </template>
                    <template v-slot:accordionContent>
                        <div
                            v-for="color in this.availableFiltersForCategoryLocal.colors.data"
                            :key="color.data.color_id"
                            @click="filterColor(color)"
                            class="w-full h-8 px-4 flex items-center justify-left text-14 cursor-pointer bg-white border border-white hover:border-black"
                            :class="color.data.clicked ? 'underlinedPositions' : ''" >
                            <div class="flex items-center justify-left rounded-full">
                                <div class="w-3 h-3 rounded-full"
                                     :style="'background-color:' + color.data.attributes.code + ';'">
                                </div>
                                <div class="ml-3 text-14 text-black"
                                     >
                                    {{ color.data.attributes.title }}
                                </div>
                            </div>
                        </div>
                    </template>
                </accordion-item>
                <accordion-item>
                    <template class="flex" v-slot:accordion-trigger="slotProps">
                        <div class="flex items-center justify-between cursor-pointer">
                            <div class="w-full my-2 flex items-center uppercase text-14 text-black">
                                Размер
                                <span class="ml-1" v-if="isSizeFilterActive">
                                         ({{ isSizeFilterActive }})
                                    </span>
                            </div>

                            <div class="flex items-center">
                                <div v-if="isSizeFilterActive">
                                    <button @click="resetSizeFilter()"
                                            class="mr-7 text-14 text-gray-main-2">
                                        сбросить
                                    </button>
                                </div>
                                <svg width="11" height="7" viewBox="0 0 11 7" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                     :class="[slotProps.visible ? 'transform rotate-180' : '']">
                                    <path d="M5.5 5.5L10 1" stroke="black" stroke-linecap="square"/>
                                    <path d="M1 1.00037L5.5 5.50037" stroke="black" stroke-linecap="square"/>
                                </svg>
                            </div>
                        </div>
                    </template>
                    <template v-slot:accordionContent>
                        <div
                            v-for="size in this.availableFiltersForCategoryLocal.sizes.data"
                            :key="size.data.size_id"
                            @click="filterSize(size)"
                            class="w-full h-8 px-4 flex items-center justify-left text-14 cursor-pointer bg-white border border-white hover:border-black"
                            :class="size.data.clicked ? 'underlinedPositions' : ''">
                            <div>
                                {{ size.data.attributes.title }}
                            </div>
                        </div>
                    </template>
                </accordion-item>

                <accordion-item>
                    <template class="flex" v-slot:accordion-trigger="slotProps">
                        <div class="flex items-center justify-between cursor-pointer">
                            <div class="w-full my-2 flex items-center uppercase text-14 text-black">
                                Наличие
                                <span class="ml-1" v-if="isInStockFilterActive">
                                         ({{ isInStockFilterActive }})
                                    </span>
                            </div>
                            <div class="flex items-center">
                                <div v-if="isInStockFilterActive">
                                    <button @click="resetStockFilter()"
                                            class="mr-7 text-14 text-gray-main-2">
                                        сбросить
                                    </button>
                                </div>
                                <svg width="11" height="7" viewBox="0 0 11 7" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                     :class="[slotProps.visible ? 'transform rotate-180' : '']">
                                    <path d="M5.5 5.5L10 1" stroke="black" stroke-linecap="square"/>
                                    <path d="M1 1.00037L5.5 5.50037" stroke="black" stroke-linecap="square"/>
                                </svg>
                            </div>
                        </div>
                    </template>
                    <template v-slot:accordionContent>
                        <div
                            v-for="stock in this.availableFiltersForCategoryLocal.stocks.data"
                            :key="stock.data.id"
                            @click="filterStock(stock)"
                            class="w-full px-4 h-9 flex items-center justify-left text-14 cursor-pointer bg-white border border-white hover:border-black"
                            :class="stock.data.clicked ? 'underlinedPositions' : ''">
                            <div >
                                {{ stock.data.attributes.title }}
                            </div>
                        </div>
                    </template>
                </accordion-item>

                <accordion-item>
                    <template class="flex" v-slot:accordion-trigger="slotProps">
                        <div class="flex items-center justify-between cursor-pointer">
                            <div class="w-full my-2 flex items-center uppercase text-14 text-black">
                                Цена
                                <span class="ml-1" v-if="isPriceFilterActive">
                                    ({{ isPriceFilterActive }})
                                </span>
                            </div>
                            <div class="flex items-center">
                                <div v-if="isPriceFilterActive">
                                    <button @click="resetPriceFilter()" class="mr-7 text-14 text-gray-main-2">сбросить</button>
                                </div>
                                <svg width="11" height="7" viewBox="0 0 11 7" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                     :class="[slotProps.visible ? 'transform rotate-180' : '']">
                                    <path d="M5.5 5.5L10 1" stroke="black" stroke-linecap="square"/>
                                    <path d="M1 1.00037L5.5 5.50037" stroke="black" stroke-linecap="square"/>
                                </svg>
                            </div>
                        </div>
                    </template>

                    <template v-slot:accordionContent>
                         <div class="px-2 pb-10">
                            <vue-slider 
                                v-model:model-value="rangeOfPrices"
                                rangeOfPrices:enable-cross="false"
                                :dotSize="14"
                                :tooltip="'none'"
                                :height="1"
                                :dot-style="customDotStyle"
                                :process="process2"
                                :min="minValue"
                                :max="maxValue"
                            >
                            </vue-slider>
                            <div class="flex justify-between mt-2 text-14 text-black">
                                <span>от {{ renderPriceFormat(rangeOfPrices[0]) }}</span>
                                <span>до {{ renderPriceFormat(rangeOfPrices[1]) }}</span>
                            </div>
                        </div>
                    </template>
                </accordion-item>
            </accordion>
        </div>
        <div class="w-98 absolute bottom-0 right-0 mt-10 z-30 ">
            <button @click="applyFilters('filter')"
                    class="w-98 h-9 flex items-center justify-center text-14 uppercase
                        bg-black font-normal text-white focus:outline-none">
                Показать
            </button>
        </div>
    </PopupOnRightSide>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Accordion from "../Accordion/Accordion.vue";
import AccordionItem from "../Accordion/AccordionItem.vue";
import PopupOnRightSide from "../popup/PopupOnRightSide.vue";
import VueSlider from 'vue-3-slider-component'
import 'vue-slider-component/theme/default.css'
import Sorting from "../FiltersAndSorting/Sorting.vue";
import vClickOutside from 'click-outside-vue3';

export default {
    name: "Filters",
    components: {Sorting, PopupOnRightSide, AccordionItem, Accordion, VueSlider},
    data: () => ({
        isSortActive: false,
        customDotStyle: {
            backgroundColor: '#ffffff',
            borderColor: '#111111',
            borderWidth: '1px',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            boxShadow: '0 0px 0px 0'
        },
        process2: dotsPos => [
            [dotsPos[0], dotsPos[1], {backgroundColor: 'black'}],
        ]
    }),
    props: {
        categoryId: null,
        isFiltersActive: null,
        sortBy: null
    },

    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        ...mapGetters({
            minValue: 'minValue',
            payload: 'payload',
            maxValue: 'maxValue',
            rangeOfPricesValue: 'rangeOfPricesValue',
            filteredProducts: 'filteredProducts',
            availableFiltersForCategoryLocal: 'availableFiltersForCategoryLocal'
        }),
        rangeOfPrices: {
            get() {
                return this.rangeOfPricesValue;
            },
            set(newValue) {
                this.setRangeOfPricesValue(newValue);
            }
        },
        isColorFilterActive() {
            return this.payload.filter.colors.length > 0 ? this.payload.filter.colors.length : null;
        },
        isSizeFilterActive() {
            return this.payload.filter.sizes.length > 0 ? this.payload.filter.sizes.length : null;
        },
        isPriceFilterActive() {
            return (this.filteredProducts.meta.currentFilters.priceFrom ?? -Infinity) > this.minValue
            || (this.filteredProducts.meta.currentFilters.priceTo ?? -Infinity) < this.maxValue
                ? '1' : null;
        },
        isInStockFilterActive() {
            return this.payload.filter.stocks.length > 0 ? this.payload.filter.stocks.length : null;
        },
        isAnyFilterActive() {
            return this.payload.filter.stocks.length
                || this.payload.filter.sizes.length
                || this.payload.filter.stocks.length
                || this.isPriceFilterActive
        },
        isSortNotDefault() {
            return this.sortBy !== 'default'
        }
    },
    methods: {
        ...mapActions({
            sortAndFilter: 'sortAndFilter',
            putColorIntoFilter: 'putColorIntoFilter',
            putSizeIntoFilter: 'putSizeIntoFilter',
            putStockIntoFilter: 'putStockIntoFilter',
            setRangeOfPricesValue: 'setRangeOfPricesValue',
            cleanPayloadFilterColors: 'cleanPayloadFilterColors',
            cleanPayloadFilterSizes: 'cleanPayloadFilterSizes',
            cleanPayloadFilterStocks: 'cleanPayloadFilterStocks',
            cleanAllFilters: 'resetAllFilters',
            putCategoriesIntoFilter: 'putCategoriesIntoFilter',
            putTypeOfSortingIntoPayload: 'putTypeOfSortingIntoPayload',
        }),

        
        resetColorFilter() {
            this.$emit('resetColorFilter', {action: 'reset'})
        },
        resetSizeFilter() {
            this.$emit('resetSizeFilter', {action: 'reset'})
        },
        resetStockFilter() {
            this.$emit('resetStockFilter', {action: 'reset'})
        },
        resetPriceFilter() {
            this.$emit('resetPriceFilter', {action: 'reset'})
        },
        resetAllFilters() {
            this.$emit('resetAllFilters', {action: 'reset'})
        },
        resetSortBy() {
            this.$emit('putTypeOfSortingIntoSortingAndFiltration', {type: 'default'})
        },

        openFilterPopup() {
            this.isFiltersPopupActive = true;
        },
        openSortingPopup() {
            this.isSortActive = true;
        },
        closePopup() {
            this.$emit('closeFilterPopup', {
                isFiltersPopupActive: false,
                isSortPopupActive: false
            })
        },
        filterColor(color) {
            this.putColorIntoFilter(color)
        },
        filterSize(size) {
            this.putSizeIntoFilter(size)
        },
        filterStock(stock) {
            this.putStockIntoFilter(stock)
        },
        async applyFilters(action) {
            this.$emit('applyFilters', action)
        },

        sort(type) {
            this.$emit('putTypeOfSortingIntoSortingAndFiltration', {type: type})
        },
    },
}

</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.underlinedPositions {
    text-decoration: underline;
    text-underline-offset: 4px;
}


</style>



