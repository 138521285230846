<template>
  <store></store>
</template>

<script>
import Store from './apps/Store.vue'

export default {
  name: 'App',
  components: {
    Store
  },
  watch: {
    $route(newPath) {
      const isCatalogPath = new String(newPath.fullPath).includes('catalog')
      if(!isCatalogPath) setTimeout(() => window.scroll({top: 0}), 100)
    }
  },
}
</script>
