<template>
    <div class="flex-none">
        <div class="flex-none lg:flex">
            <div class="w-full lg:w-1/2">
                <div class="lg:h-180 overflow-hidden relative">
                    <img class="object-cover h-full w-full"
                         :src="giftCertificateBlock?.data.attributes.certificate_image.data.attributes.path" alt="">
                </div>
            </div>
            <div class="w-full lg:w-1/2 lg:mx-auto font-normal">
                <div class="px-6 text-gray-main text-lg sticky lg:px-0 lg:pl-15 lg:text-left lg:w-100">
                    <div class=" text-gray-main text-base font-normal">
                        <div class="w-full mt-4 text-xl uppercase text-gray-main text-center lg:ml-4 lg:text-left ">
                            {{ giftCertificateBlock?.data.attributes.title }}
                        </div>
                        <div>
                            <AuthByPhone v-if="getVerificationOfUser"
                                         @authByPhoneSuccessClosePopup="getUserAuthData"/>
                            <div v-else class="w-full mt-4 text-base text-gray-main lg:ml-4 lg:text-left ">
                                <div>
                                    <div class="px-2 lg:p-0 lg:items-left lg:justify-left lg:pr-4 ">
                                        <div class="w-full">Выберите сумму</div>
                                        <div v-click-outside="hideSelect">
                                            <div
                                                class="relative h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:mx-0">
                                                <div
                                                    class="w-full border border-gray-lighter cursor-pointer align-middle flex items-center justify-left pl-3 h-10"
                                                    @click="areOptionsVisible = !areOptionsVisible">
                                                    <div v-if="this.sum === 0"
                                                         :class="[submitted && v$.sum.$error ? 'border-red-400' : 'border-gray-lighter'] ">
                                                        <div v-if="submitted && v$.sum.$error"
                                                             class="font-medium tracking-wide text-xs font-gothic text-red-500 mt-1 ml-1">
                                                            Выберите сумму
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        {{ renderPriceFormat(this.sum) }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="w-full absolute left-0 top-0 mt-10 z-10 border-l border-r border-b bg-white cursor-pointer"
                                                    v-if="areOptionsVisible">
                                                    <div v-for="sum in sums"
                                                         :key="sum.id"
                                                         class="w-full hover:bg-black hover:text-white bg-white h-10 align-middle flex items-center justify-left pl-3 cursor-pointer"
                                                         @click="changeSumOfCertificate(sum)">
                                                        {{ renderPriceFormat(sum.title) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4 px-2 lg:p-0 lg:items-left lg:justify-left">
                                        <div class="w-full ">Имя отправителя</div>
                                        <div
                                            class="h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0  lg:pr-4  lg:mx-0">
                                            <input
                                                class="appearance-none rounded-none w-full h-full border px-2 focus:outline-none focus:border-black"
                                                type="text"
                                                v-model="senderName"
                                                name="senderName"
                                                :class="[submitted && v$.senderName.$error ? 'border-red-400' : 'border-gray-lighter'] ">
                                            <div v-if="submitted && v$.senderName.$error"
                                                 class="font-medium tracking-wide text-xs font-gothic text-red-500 text-xs mt-1 ml-1">
                                                Заполните
                                                имя отправителя
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4 px-2 lg:p-0 lg:items-left lg:justify-left">
                                        <div class="w-full ">E-mail отправителя</div>
                                        <div
                                            class="h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0  lg:pr-4  lg:mx-0">
                                            <input
                                                class="appearance-none rounded-none w-full h-full border px-2 focus:outline-none focus:border-black"
                                                type="text"
                                                v-model="senderEmail"
                                                name="name"
                                                :class="[submitted && v$.senderEmail.$error ? 'border-red-400' : 'border-gray-lighter'] ">
                                            <div v-if="submitted && v$.senderEmail.$error"
                                                 class="font-medium tracking-wide text-xs font-gothic text-red-500 text-xs mt-1 ml-1">
                                                Заполните
                                                email отправителя
                                            </div>

                                        </div>
                                    </div>
                                    <div class="mt-4 px-2 lg:p-0 lg:items-left lg:justify-left">
                                        <div class="w-full ">Номер телефона отправителя</div>
                                        <div
                                            class="h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0  lg:pr-4  lg:mx-0">
                                            <input
                                                class="appearance-none rounded-none w-full h-full border px-2 focus:outline-none focus:border-black"
                                                readonly
                                                v-model="senderPhoneNumber">
                                        </div>
                                    </div>

                                    <div class="pr-2 mt-4 text-gray-main flex items-center">
                                        <input v-model="checkbox" type="checkbox" name="checkbox"
                                               class="ml-2 h-4 w-4 form-checkbox text-black border border-black focus:border-black focus:outline-none lg:ml-0 "
                                               checked>
                                        <div class="ml-3">Отправить сертификат мне, я сам(а) вручу его получателю!</div>
                                    </div>

                                    <div v-if="checkbox !== true">
                                        <div class="mt-4 px-2 lg:p-0 lg:items-left lg:justify-left">
                                            <div class="w-full ">Имя получателя</div>
                                            <div
                                                class="h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0  lg:pr-4  lg:mx-0">
                                                <input
                                                    class="appearance-none rounded-none w-full h-full border px-2 focus:outline-none focus:border-black"
                                                    type="text"
                                                    v-model="recipientName"
                                                    :class="[submitted && v$.recipientName.$error ? 'border-red-400' : 'border-gray-lighter'] ">
                                                <div
                                                    v-if="submitted && v$.recipientName.$error"
                                                    class="font-medium tracking-wide text-xs font-gothic text-red-500 text-xs mt-1 ml-1">
                                                    Заполните имя получателя
                                                </div>

                                            </div>
                                        </div>

                                        <div class="mt-4 px-2 lg:p-0 lg:items-left lg:justify-left">
                                            <div class="w-full">E-mail получателя</div>
                                            <div
                                                class="h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0  lg:pr-4  lg:mx-0">
                                                <input
                                                    class="appearance-none rounded-none w-full h-full border px-2 focus:outline-none focus:border-black"
                                                    type="email"
                                                    v-model="recipientEmail"
                                                    :class="[submitted && v$.recipientEmail.$error ? 'border-red-400' : 'border-gray-lighter'] ">
                                                <div
                                                    v-if="submitted && v$.recipientEmail.$error"
                                                    class="font-medium tracking-wide text-xs font-gothic text-red-500 text-xs mt-1 ml-1">
                                                    Заполните email получателя
                                                </div>

                                            </div>
                                        </div>
                                        <div class="mt-4 px-2 lg:p-0 lg:items-left lg:justify-left">
                                            <div class="w-full">Дата и время отправки</div>
                                            <div
                                                class="h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0  lg:pr-4  lg:mb-0 lg:mx-0">
                                                <input
                                                    class="w-full h-10 rounded-none border border-gray-lighter px-2 focus:outline-none focus:border-black"
                                                    v-model="dateTime" type="datetime-local" id="dateTime"
                                                    :class="[submitted && v$.dateTime.$error ? 'border-red-400' : 'border-gray-lighter'] ">
                                                <div v-if="submitted && v$.dateTime.$error"
                                                     class="font-medium tracking-wide text-xs font-gothic text-red-500 text-xs mt-1 ml-1">
                                                    Выберите
                                                    дату и время отправки
                                                </div>

                                            </div>
                                            <div>*По Московскому времени</div>
                                        </div>
                                        <div class="mt-4 px-2 lg:p-0 lg:items-left lg:justify-left">
                                            <div class="w-full">Сообщение получателю</div>
                                            <div
                                                class="h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0  lg:pr-4  lg:mb-4 lg:mx-0">
                                        <textarea
                                            class="w-full h-18 appearance-none rounded-none border border-gray-lighter px-2 focus:outline-none focus:border-black"
                                            v-model="message"
                                            type="text"
                                            name="comment">
                                        </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-15 px-2 lg:p-0 lg:items-left lg:justify-left">
                                        <div
                                            class="h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4">
                                            <button
                                                class="w-full py-3 bg-black text-white border border-black text-xl font-normal focus:outline-none"
                                                @click="buyCertificate">
                                                Приобрести
                                            </button>
                                        </div>
                                    </div>
                                    <div class="mt-5 px-2 lg:p-0 lg:items-left lg:justify-left">
                                        <div
                                            class="h-8 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4">
                                            <button data-popmechanic-call-form="77584"
                                                    class="w-full py-3 bg-white text-black border border-black text-xl font-normal focus:outline-none">
                                                Намекнуть о подарке
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-6 sm:p-12 w-full h-full lg:w-256 lg:mx-auto text-gray-main font-normal">
            <div class="my-4 text-xl text-gray-main text-center font-normal uppercase flex-1">Условия использования
                сертификата
            </div>
            <div class="p-2 lg:p-4 ">
                <accordion class="mt-7">
                    <accordion-item>
                        <template slot="accordion-trigger">
                            <div class="w-full py-3 flex">
                                <div class="text-gray-main text-left text-lg font-semibold flex-1">
                                    О продукте
                                </div>
                                <div class="mr-4 flex-initial">
                                    <span class="Collapsible__Plus"></span>
                                </div>
                            </div>
                        </template>
                        <template v-slot:accordionContent>
                            <div class="pt-1 pb-3">
                                <div class="text-gray-main text-left text-lg"
                                     v-html="giftCertificateBlock?.data.attributes.description"></div>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item>
                        <template slot="accordion-trigger">
                            <div class="w-full py-3 flex">
                                <div class="text-gray-main text-left text-lg font-semibold flex-1">
                                    Правила использования сертификата
                                </div>
                                <div class="mr-4 flex-initial">
                                    <span class="Collapsible__Plus"></span>
                                </div>
                            </div>
                        </template>
                        <template v-slot:accordionContent>

                            <div class="pt-1 pb-3">
                                <div class="text-gray-main text-left text-lg"
                                     v-html="giftCertificateBlock?.data.attributes.more_information"></div>
                            </div>
                        </template>
                    </accordion-item>
                </accordion>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import AccordionItem from "../components/AccordionItem"
import Accordion from "../components/Accordion"
import vClickOutside from 'click-outside-vue3'
import AuthByPhone from "../components/Auth/AuthByPhone.vue"


export default {
    name: "GiftCertificate",
    data: () => ({
        sums: [
            {id: 1, title: 5000},
            {id: 2, title: 10000},
            {id: 3, title: 15000},
            {id: 4, title: 20000},
            {id: 5, title: 30000},
            {id: 6, title: 40000},
            {id: 7, title: 50000},
            {id: 8, title: 100000}
        ],

        ownSum: '',
        sum: 5000,
        senderName: '',
        recipientName: '',
        senderEmail: '',
        recipientEmail: '',
        senderPhoneNumber: '',
        message: '',
        dateTime: null,
        dateWithGTM: null,
        senderPhoneIsNotCorrect: false,

        submitted: false,
        areOptionsVisible: false,
        checkbox: false,
    }),
    setup () { return { v$: useVuelidate() } },
    validations() {
        if (this.checkbox === true) {
            return {
                sum: {required},
                senderName: {required},
                senderEmail: {required, email},
                senderPhoneNumber: {required},
                recipientName: {},
                recipientEmail: {},
                dateTime: {},
                message: {}
            }
        } else {
            return {
                sum: {required},
                senderName: {required},
                senderEmail: {required, email},
                senderPhoneNumber: {required},
                recipientName: {required},
                recipientEmail: {required, email},
                dateTime: {},
                message: {}
            }
        }
    },
    components: {
        AuthByPhone,
        AccordionItem, Accordion
    },
    computed: {
        ...mapGetters({
            giftCertificateBlock: 'giftCertificateBlock',
            giftCertificateBlockStatus: 'giftCertificateBlockStatus',
            giftCertificateOrder: 'giftCertificateOrder',
            user: 'user',
            userEmail: 'userEmail',
            authInfo: 'authInfo',
        }),
        getVerificationOfUser() {
            return !this.authInfo?.authStatus || (this.authInfo?.authStatus
                && !this.authInfo?.relations?.authUser?.data?.attributes?.isPhoneVerified)
        },
        styleClasses() {
            if (this.submitted && this.v$.senderPhoneNumber.$invalid) {
                return 'vue-tel-input-error appearance-none w-full h-full border px-2 tracking-wide'
            } else {
                return 'appearance-none appearance-none w-full h-full border px-2 tracking-wide'
            }
        },
        takeDateTimeWithGTM() {

            this.dateWithGTM = null

            if (this.dateTime != null) {
                this.dateWithGTM = new Date(this.dateTime)
                this.dateWithGTM.toString()
            }
        }

    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        ...mapActions({
            createGiftCertificateOrder: 'createGiftCertificateOrder',
            getGiftCertificateBlock: 'getGiftCertificateBlock',
            getAuthStatus: 'getAuthStatus',
        }),
        async getUserAuthData() {
            await this.$store.dispatch('getAuthStatus')
            this.senderEmail = this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail
                ? this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail?.data?.attributes?.email
                : this.authInfo?.relations?.authUser?.data?.relations?.currentMarketingEmail?.data?.attributes?.email
            this.senderPhoneNumber = this.authInfo?.relations?.authUser?.data?.attributes?.phone
        },

        telValidate(telnumber, telnumberObject) {
            if (telnumberObject.valid || telnumber.length < 1) {
                this.senderPhoneNumber = telnumberObject.number
                this.senderPhoneIsNotCorrect = false
            } else {
                this.senderPhoneNumber = ''
                this.senderPhoneIsNotCorrect = true
            }

        },
        changeSumOfCertificate(option) {
            this.sum = option.title

            this.hideSelect();
        },
        hideSelect() {
            this.areOptionsVisible = false;
        },

        async buyCertificate() {
            this.submitted = true;

            await this.v$.$touch();

            if (!this.v$.$invalid) {
                await this.createGiftCertificateOrder({
                    "recipientIsSender": this.checkbox,
                    "recipientName": this.checkbox === true ? this.senderName : this.recipientName,
                    "recipientEmail": this.checkbox === true ? this.senderEmail : this.recipientEmail,
                    "messageToRecipient": this.messageToRecipient,
                    "sendDatetime": this.dateTime === null
                        ? new Date((new Date().setHours(new Date().getHours() - (new Date().getTimezoneOffset() / 60)))).toISOString().slice(0, 19)
                        : this.dateTime,
                    "amount": this.sum,
                    "senderEmail": this.senderEmail,
                    "senderName": this.senderName,
                })
                    .then(() => {
                        window.location.href = this.giftCertificateOrder.data.relationships.payments.data[0].data.attributes.invoice_url;
                    })
                    .catch(() => {

                    });
            }
        }
    },

    async mounted() {
        await this.getUserAuthData()
        await this.$store.dispatch('getGiftCertificateBlock', '1');
    },

    beforeDestroy() {
        document.removeEventListener('click', this.hideSelect)
    },
    head: {
        title: 'Подарочный сертификат - Present & Simple',
        meta: function () {
            return [
                {
                    name: 'description',
                    content: 'Подарочный сертификат - Модная одежда и аксессуары от Российского производителя Present & Simple с доставкой'
                }
            ]
        }

    },

}
</script>

<style scoped>

</style>
