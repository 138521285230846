<template>
    <div v-if="footerStatus=='success'" class=" mx-6 sm:mx-12 flex flex-col sm:flex-row items-top border-t border-gray-lighter">
        
        <div v-if="!isMobile" class="lg:my-[75px] my-6 sm:my-12 w-full sm:w-1/4">
            <div class="mt-2">
                <img :src="`/resources/images/footer/app_qr.svg`" />
            </div>
            <p class="text-14 mt-[14px]">
                <a href="https://play.google.com/store/apps/details?id=ru.presentsimple.android" target="_blank">Приложение Present & Simple</a>
            </p>
            <p class="text-14 text-gray-light mt-[10px]">Чтобы скачать приложение, наведите <br />камеру на QR-код</p>
        </div>

        <div class="lg:my-[75px] my-6 sm:my-12 sm:w-1/4">
            <div class="mt-[-10px]">
                <div v-for="(item_category, index) in footer.data" :key="item_category.data.footer_category_id">
                    <div v-for="sub_item_category in item_category.data.attributes.footer_items.data"
                        :key="sub_item_category.data.footer_item_id"
                        :class="['mt-2']">
                        <a class="text-14 font-normal" :href="sub_item_category.data.attributes.path" target="_blank">{{sub_item_category.data.attributes.title}}</a>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isMobile" class="lg:my-[75px] my-6 sm:my-12 sm:w-1/4">
            <div class="mt-[-10px] mb-[12px] flex justify-between">
                <a class="app-link__btn" href="https://apps.apple.com/ru/app/present-simple-%D0%BC%D0%B0%D0%B3%D0%B0%D0%B7%D0%B8%D0%BD-%D0%BE%D0%B4%D0%B5%D0%B6%D0%B4%D1%8B/id6736971713" target="_blank">
                    <img src="/landing/images/apple_logo.svg" />
                    <span>App Store</span>
                </a>
                <a class="app-link__btn" href="https://apps.apple.com/ru/app/present-simple-%D0%BC%D0%B0%D0%B3%D0%B0%D0%B7%D0%B8%D0%BD-%D0%BE%D0%B4%D0%B5%D0%B6%D0%B4%D1%8B/id6736971713" target="_blank">
                    <img src="/landing/images/google_pay_logo.svg" />
                    <span>Google Play</span>
                </a>
            </div>
        </div>

        <div v-if="isMobile" class="lg:my-[75px] my-6 sm:my-12 w-full sm:w-1/4">
            <p class="text-14 mb-[15px]">Узнавайте первыми о специальных предложениях<br/> и секретных распродажах</p>
            <SubscribeForm></SubscribeForm>
        </div>

        <div class="lg:my-[75px] my-6 sm:my-12 sm:w-1/4">
            <div class="mt-[-10px]">
                <p class="text-14 mt-2" v-if="!isMobile">Контакты</p>
                <div class="mt-[10px] w-full flex">
                    <a href="https://wa.me/78003010424"  class="text-14 font-normal" target="_blank">WhatsApp</a>
                    <a href="https://t.me/presentandsimple_com" class="text-14 font-normal ml-3 " target="_blank">Telegram</a>
                    <a href="https://vk.com/presentandsimplecom" class="text-14 font-normal ml-3 " target="_blank">VK</a>
                </div>
                <div class="mt-[10px]">
                    <a href="tel:+78003010424" class="text-14">
                        +7 (800) 301-04-24
                    </a>
                </div>
                <div class="mt-[10px]">
                    <span class="text-14 text-gray-light">Ежедневно 10:00–19:00 мск</span>
                </div>
            </div>
        </div>
        
        <div v-if="!isMobile" class="lg:my-[75px] my-6 sm:my-12 w-full sm:w-1/4">
            <div class="mt-[-10px]">
                <p class="text-14 mb-[15px] mt-2">Узнавайте первыми о специальных<br/> предложениях и секретных распродажах</p>
                <SubscribeForm></SubscribeForm>
            </div>
        </div>
    </div>
</template>
<script>
    import SubscribeForm from '../SubscribeForm.vue'
    import { mapGetters } from "vuex";

    export default {
        name: "Footer",
        components: { SubscribeForm },
        data() {
            return {
                windowWidth: window.innerWidth,
            }
        },
        computed: {
            ...mapGetters({
                footer: 'footer',
                footerStatus: 'footerStatus',
            }),
            isMobile() {
                return this.windowWidth < 750
            },
        },
        mounted () {
            this.$store.dispatch('fetchFooter');
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth
            })
        },
    }
</script>

<style lang="scss">
    .app-link__btn {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        height: 38px;
        line-height: 38px;
        border: 1px solid #000;
        flex: 1;

        &:first-child {
            margin-right: 10px;
        }

        img {
            width: 12.5px;
            margin-right: 12px;
        }
    }
</style>